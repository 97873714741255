import {
  AssetDisplayVariation,
  AssetDisplayVariations,
  DisplayType,
} from '@amzn/coral_com-amazon-sssapinterface-model';

import { convertToSentenceCase } from './formatting-helpers';

export const extractAssetDisplayType = (
  assets: AssetDisplayVariations,
  type: DisplayType = DisplayType.STANDARD
): AssetDisplayVariation | undefined => assets.find((asset) => asset.displayType === type);

// TODO translate ResourceType
// https://app.asana.com/0/1206972973144714/1207532545418033/f
// Removes underscore and only capitalizes first letter of the first word
export const formatResourceTypeForPill = (tag: string) => convertToSentenceCase(tag.replace(/_/g, ' '));
