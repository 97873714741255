import { ReactElement, forwardRef } from 'react';

import { Heading, HEADING_SIZE } from 'src/components/atoms/heading/Heading';
import styles from 'src/components/common/dynamic-list/DynamicList.module.scss';
import { DynamicListSVG } from 'src/components/common/dynamic-list/DynamicListSVG';

type DesktopHeaderProps = {
  heading: string;
  activeSlide: number;
};

// This dummy component exists to improve code readability
// it is tested on DynamicList.test.tsx suite
const DesktopHeader = forwardRef<HTMLDivElement, DesktopHeaderProps>(
  ({ heading, activeSlide }, ref): ReactElement => (
    <div ref={ref} className={styles.imageContainer}>
      <Heading as="h2" size={HEADING_SIZE.h2}>
        {heading}
      </Heading>
      {DynamicListSVG && <DynamicListSVG activeSlide={activeSlide} />}
    </div>
  )
);

DesktopHeader.displayName = 'MobileHeader';

export default DesktopHeader;
