import { FunctionComponent } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { PageSection, SmallBanner } from 'src/components/common';
import { GETTING_STARTED_PAGE_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers';

export const ClimatePledgeSection: FunctionComponent = () => {
  const [gettingStartedBundle] = useBundle(GETTING_STARTED_PAGE_BUNDLE_NAME);
  return (
    <PageSection theme="green-primary">
      <SmallBanner
        heading={t(gettingStartedBundle, 'climatePledge_heading')}
        cta={{
          label: t(gettingStartedBundle, 'climatePledgeButton_label'),
          href: 'https://www.theclimatepledge.com/',
        }}
        image={{
          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/TCPglobalcomponent-2.png',
          alt: 'Wind turbines on a hillside',
        }}
        logo
      />
    </PageSection>
  );
};
