import {
  DisplayType,
  PageSections,
  Resource,
  SectionType,
  DisplayFacet,
} from '@amzn/coral_com-amazon-sssapinterface-model';

import AllResources from 'src/fixtures/structures/resources';

import { extractAssetDisplayType } from './resource-helpers';

// TODO add unit tests for this helper module
// https://app.asana.com/0/1206972973144714/1207508789657461/f

export const getSectionsByType = (sections: PageSections, type: SectionType): PageSections =>
  sections.filter((section) => section.type === type);

export const getResourcesBySectionType = (sections: PageSections, type: SectionType): Resource[] =>
  getSectionsByType(sections, type)[0].resources.map((id) => AllResources[id]);

export const extractAssetBySectionAndDisplay = (
  sections: PageSections,
  sectionType: SectionType,
  displayType = DisplayType.STANDARD
): string | undefined => {
  const resource = getResourcesBySectionType(sections, sectionType)?.[0];
  return extractAssetDisplayType(resource.assets.primaryAsset, displayType)?.assetUrl;
};

export const extractDisplayFacetBySection = (
  sections: PageSections,
  sectionType: SectionType
): DisplayFacet | undefined => getResourcesBySectionType(sections, sectionType)?.[0]?.display;
