import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { KatBox } from '@amzn/katal-react';
import { useBundle } from '@amzn/react-arb-tools';

import { LOCAL_STORAGE_KEYS, getLocalValue, setLocalValue } from 'src/clients/local-storage-client';
import { Filters, FilterEntryProps, SecondaryBanner, TextComponent, MainBanner } from 'src/components/common';
import { ClimatePledgeSection } from 'src/components/templates/ClimatePledgeSection';
import { GETTING_STARTED_PAGE_BUNDLE_NAME } from 'src/constants';
import { ROUTES } from 'src/data/enums/Routes';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';

import { FocusAreaTakeAction } from './FocusAreaSubSection';
import styles from './GettingStartedPage.module.scss';
import {
  FilterBannerSection,
  MeasurementSection,
  ScienceExchangeSection,
  TakeActionSection,
} from './GettingStartedSections';

const FilterBox = styled(KatBox)`
  --border-radius: 24px;
  --padding: 3rem;
`;

interface GettingStartedFilters {
  measurement?: boolean;
  carbonFreeEnergy?: boolean;
  buildings?: boolean;
  transportation?: boolean;
  retailProducts?: boolean;
  water?: boolean;
  humanRights?: boolean;
  carbonNeutralization?: boolean;
}

const DEFAULT_GETTING_STARTED_FILTERS: GettingStartedFilters = {
  measurement: false,
  carbonFreeEnergy: false,
  buildings: false,
  transportation: false,
  retailProducts: false,
  water: false,
  humanRights: false,
  carbonNeutralization: false,
};

const takeActionLink = '#takeAction';
const measurementLink = '#measure';

export const GettingStartedPage: FunctionComponent = () => {
  const [gettingStartedBundle] = useBundle(GETTING_STARTED_PAGE_BUNDLE_NAME);

  const maybeStoredFilters = getLocalValue(LOCAL_STORAGE_KEYS.ASH_GETTING_STARTED_FILTERS);
  const startingFilters = maybeStoredFilters ? JSON.parse(maybeStoredFilters) : DEFAULT_GETTING_STARTED_FILTERS;
  const [filters, setFilters] = useState<GettingStartedFilters>(startingFilters);

  const handleFilterChange = (e: any): void => {
    const filterKey = e.currentTarget.name ?? '';
    let selectedOption: string | boolean = false;

    if (e.detail?.value) {
      selectedOption = e.detail.value;
    } else if (e.detail?.checked || e.detail?.unchecked) {
      selectedOption = e.detail.checked ?? e.detail.unchecked;
    }

    setFilters((prevState) => {
      const newState = { ...prevState, [filterKey]: selectedOption };
      setLocalValue(LOCAL_STORAGE_KEYS.ASH_GETTING_STARTED_FILTERS, JSON.stringify(newState));
      return newState;
    });
  };

  const GETTING_STARTED_FILTER_CONFIG: FilterEntryProps[] = [
    {
      name: 'measurement',
      label: t(gettingStartedBundle, 'filter1_label') ?? '',
      controlType: 'toggle',
      onFilterChange: handleFilterChange,
    },
    {
      name: 'carbonFreeEnergy',
      label: t(gettingStartedBundle, 'filter2_label') ?? '',
      controlType: 'toggle',
      onFilterChange: handleFilterChange,
    },
    {
      name: 'buildings',
      label: t(gettingStartedBundle, 'filter3_label') ?? '',
      controlType: 'toggle',
      onFilterChange: handleFilterChange,
    },
    {
      name: 'transportation',
      label: t(gettingStartedBundle, 'filter4_label') ?? '',
      controlType: 'toggle',
      onFilterChange: handleFilterChange,
    },
    {
      name: 'retailProducts',
      label: t(gettingStartedBundle, 'filter5_label') ?? '',
      controlType: 'toggle',
      onFilterChange: handleFilterChange,
    },
    {
      name: 'water',
      label: t(gettingStartedBundle, 'filter6_label') ?? '',
      controlType: 'toggle',
      onFilterChange: handleFilterChange,
    },
    {
      name: 'humanRights',
      label: t(gettingStartedBundle, 'filter7_label') ?? '',
      controlType: 'toggle',
      onFilterChange: handleFilterChange,
    },
    {
      name: 'carbonNeutralization',
      label: t(gettingStartedBundle, 'filter8_label') ?? '',
      controlType: 'toggle',
      onFilterChange: handleFilterChange,
    },
  ];

  // TODO: Replace with actual Impact area resource values */
  // https://app.asana.com/0/0/1207456989932703/f
  const renderedImpactAreas = [
    ...(filters.carbonFreeEnergy ? ['carbon-freeenergy'] : []),
    ...(filters.buildings ? ['buildings'] : []),
    ...(filters.transportation ? ['transportation'] : []),
    ...(filters.retailProducts ? ['wasteandcircularity'] : []),
    ...(filters.water ? ['waterstewardship'] : []),
    ...(filters.humanRights ? ['humanrights'] : []),
    ...(filters.carbonNeutralization ? ['carbonneutralization'] : []),
  ];

  const isAnyFilterSet = renderedImpactAreas.length > 0 || filters.measurement;

  return (
    <>
      <FilterBannerSection>
        <TextComponent
          className={styles.textComponent}
          headingSize="h1"
          heading={t(gettingStartedBundle, 'gettingStarted_heading')}
          copy={t(gettingStartedBundle, 'gettingStarted_description')}
          copyClassName={styles.textComponentCopy}
          headingClassName={styles.textComponentHeading}
          contentClassName={styles.textComponentContent}
        />
        <FilterBox variant="ltgrey">
          <Filters
            values={filters as Record<string, string | boolean>}
            heading={t(gettingStartedBundle, 'filter_heading')}
            filters={GETTING_STARTED_FILTER_CONFIG}
            cta={
              isAnyFilterSet
                ? {
                    label: t(gettingStartedBundle, 'filterCta_label') ?? '',
                    url: filters.measurement ? measurementLink : takeActionLink,
                    icon: 'chevronDown',
                  }
                : undefined
            }
          />
        </FilterBox>
      </FilterBannerSection>

      {filters.measurement && (
        <MeasurementSection id="measure">
          <MainBanner
            theme={SECTION_THEME.Light}
            cta={{
              label: t(gettingStartedBundle, 'measurementCta_label') ?? '',
              href: ROUTES.MEASUREMENT,
              icon: 'chevronRight',
              target: '_self',
            }}
            title={t(gettingStartedBundle, 'measurement_heading') ?? ''}
            subtitle={t(gettingStartedBundle, 'measurement_description') ?? ''}
            imageUrl="https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/gettingstarted-measurement.png"
            accordions={[
              {
                title: t(gettingStartedBundle, 'measurementAccordion1_title') ?? '',
                content: [{ text: t(gettingStartedBundle, 'measurementAccordion1_description') ?? '' }],
              },
              {
                title: t(gettingStartedBundle, 'measurementAccordion2_title') ?? '',
                content: [{ text: t(gettingStartedBundle, 'measurementAccordion2_description') ?? '' }],
              },
              {
                title: t(gettingStartedBundle, 'measurementAccordion3_title') ?? '',
                content: [{ text: t(gettingStartedBundle, 'measurementAccordion3_description') ?? '' }],
              },
            ]}
          />
        </MeasurementSection>
      )}

      {renderedImpactAreas.length > 0 && (
        <TakeActionSection id="takeAction">
          <TextComponent
            className={styles.takeAction}
            heading={t(gettingStartedBundle, 'takeAction_heading') ?? ''}
            copy={t(gettingStartedBundle, 'takeAction_description')}
            theme={SECTION_THEME.Light}
          />
          {renderedImpactAreas.map((impactArea) => (
            <FocusAreaTakeAction key={impactArea} focusAreaId={impactArea} />
          ))}
        </TakeActionSection>
      )}

      <ScienceExchangeSection>
        <SecondaryBanner
          imagePrimary={{
            url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/home-thescienceexchange-1.png',
            alt: t(gettingStartedBundle, 'gettingStarted_primaryImageAltText') ?? '',
          }}
          imageSecondary={{
            url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/home-thescienceexchange-2.png',
            alt: t(gettingStartedBundle, 'gettingStarted_secondaryImageAltText') ?? '',
          }}
          subTitle=""
          title={t(gettingStartedBundle, 'scienceExchange_heading') ?? ''}
          description={t(gettingStartedBundle, 'scienceExchange_description') ?? ''}
          theme="dark"
          cta={{
            label: t(gettingStartedBundle, 'scienceExchangeCta_label') ?? '',
            link: '/science-exchange',
          }}
        />
      </ScienceExchangeSection>

      <ClimatePledgeSection />
    </>
  );
};
