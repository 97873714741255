import classNames from 'classnames';
import { type ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import { Icon } from 'src/components/atoms';
import { ListItem } from 'src/components/common';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './Accordion.module.scss';

export enum ACCORDION_VARIANT {
  MainBanner = 'main-banner',
  AccordionComponent = 'accordion-component',
}

export type AccordionProps = {
  theme?: SECTION_THEME;
  variant?: ACCORDION_VARIANT;
  title?: string;
  subtitle?: string;
  content: {
    image?: ImageAttributes;
    text?: string;
    subtitle?: string;
    secondText?: string;
    links?: { label: string; url: string }[];
  }[];
};

type AccordionComponentProps = AccordionProps & {
  isOpen: boolean;
  onClick(): void;
};

export function Accordion({
  theme = SECTION_THEME.Dark,
  variant = ACCORDION_VARIANT.MainBanner,
  title,
  subtitle,
  content,
  isOpen,
  onClick,
}: AccordionComponentProps): ReactElement {
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    } else {
      setContentHeight(0);
    }
  }, [isOpen, contentRef]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        onClick();
      }
    },
    [onClick]
  );

  return (
    <div className={classNames(styles.accordion, styles[theme])}>
      <div
        className={styles.accordionHeader}
        onClick={onClick}
        onKeyDown={onKeyDown}
        role="button"
        tabIndex={0}
        aria-expanded={isOpen}
      >
        <h4 className={classNames(styles.accordionTitle, styles[theme], styles[variant], isOpen && styles.isOpen)}>
          {title}
        </h4>
        <button
          type="button"
          className={classNames(
            styles.accordionToggle,
            styles[variant],
            styles[theme],
            styles[isOpen ? 'open' : 'closed']
          )}
          aria-label={isOpen ? 'Collapse' : 'Expand'}
        >
          <Icon name={isOpen ? 'minus' : 'plus'} className={styles.accordionIcon} />
        </button>
      </div>
      <div className={styles.content} ref={contentRef} style={{ height: contentHeight }} aria-hidden={!isOpen}>
        {subtitle && <p className={classNames(styles.subtitle, styles[theme])}> {subtitle} </p>}
        {variant === ACCORDION_VARIANT.MainBanner && (
          <p className={classNames(styles.accordionContent, styles[theme])}> {content[0].text} </p>
        )}
        {variant === ACCORDION_VARIANT.AccordionComponent &&
          content.map((item) => (
            <ListItem
              key={`${item.text}-${item.subtitle}`}
              theme={theme}
              title={item.subtitle}
              headingSize="h5"
              subtitle={item.text}
              image={item.image}
              links={item.links}
            />
          ))}
      </div>
    </div>
  );
}
