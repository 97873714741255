import { FunctionComponent } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import {
  Card,
  PageSection,
  SecondaryBanner,
  Slider,
  SmallBanner,
  TextComponent,
  PrimaryHero,
} from 'src/components/common';
import AssetBanner from 'src/components/common/asset-banner/AssetBanner';
import { Grid } from 'src/components/common/grid/Grid';
import { useFocusAreaCards } from 'src/components/pages/home/hooks/useFocusAreaCards';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/constants';
import { ROUTES } from 'src/data/enums/Routes';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';
import useDeviceSize from 'src/hooks/useDeviceSize.hook';

export const HomePage: FunctionComponent = () => {
  const [homepageBundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  const { isSmallDesktop } = useDeviceSize();
  const imageVariant = isSmallDesktop ? 'desktop' : 'mobile';
  const imageRoot = `https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/${imageVariant}`;
  const focusAreaCards = useFocusAreaCards();

  return (
    <>
      <PrimaryHero
        heading={t(homepageBundle, 'hero_title') ?? ''}
        description={t(homepageBundle, 'hero_description')}
        image="https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/home-hero.png"
      />
      <PageSection>
        {/* TODO: The Slider component uses TextComponent which has the below bug. */}
        {/* TODO: Copy is required when it should be optional and text not full-width due to copy still injected in dom */}
        {/* TODO: https://app.asana.com/0/1207500805232850/1207595341447795 */}
        {/* TODO: Also, extra padding on the slides causes it not to align with the heading: https://app.asana.com/0/1207500805232850/1207595341447807 */}
        {/* TODO: Add alt text to these card images when fixed (strings are already created): https://app.asana.com/0/1207500805232850/1207615185237716 */}
        <Slider heading={t(homepageBundle, 'news_title') ?? ''} displayContents>
          <Card
            title={t(homepageBundle, 'news_card1Title')}
            subtitle={t(homepageBundle, 'news_card1Description')}
            image={`${imageRoot}/home-newsandhighlights-getstarted.png`}
            alt={t(homepageBundle, 'news_card1ImageAltText')}
            link={{
              label: t(homepageBundle, 'news_card1CTA') ?? '',
              url: ROUTES.GETTING_STARTED,
            }}
            target="_self"
            icon="chevronRight"
          />
          <Card
            title={t(homepageBundle, 'news_card2Title')}
            subtitle={t(homepageBundle, 'news_card2Description')}
            image={`${imageRoot}/home-newsandhighlights-measurement.png`}
            alt={t(homepageBundle, 'news_card2ImageAltText')}
            link={{
              label: t(homepageBundle, 'news_card2CTA') ?? '',
              url: ROUTES.MEASUREMENT,
            }}
            target="_self"
            icon="chevronRight"
          />
          <Card
            title={t(homepageBundle, 'news_card3Title')}
            subtitle={t(homepageBundle, 'news_card3Description')}
            image={`${imageRoot}/home-newsandhighlights-DPM.png`}
            alt={t(homepageBundle, 'news_card3ImageAltText')}
            link={{
              label: t(homepageBundle, 'news_card3CTA') ?? '',
              url: ROUTES.SCIENCE_EXCHANGE,
            }}
            target="_self"
            icon="chevronRight"
          />
        </Slider>
      </PageSection>

      <AssetBanner
        description={t(homepageBundle, 'approach_description') ?? ''}
        heading={t(homepageBundle, 'approach_title') ?? ''}
        cta={{
          link: ROUTES.GETTING_STARTED,
          copy: t(homepageBundle, 'approach_ctaLabel') ?? '',
        }}
      />

      <PageSection theme="slate-dark">
        <TextComponent
          copy={t(homepageBundle, 'focusAreas_description')}
          heading={t(homepageBundle, 'focusAreas_title') ?? ''}
        />
        <Grid cards={focusAreaCards} />
      </PageSection>

      <PageSection>
        <SecondaryBanner
          imagePrimary={{
            url: `${imageRoot}/home-thescienceexchange-1.png`,
            alt: t(homepageBundle, 'scienceExchange_imageOneAltText') ?? '',
          }}
          imageSecondary={{
            url: `${imageRoot}/home-thescienceexchange-2.png`,
            alt: t(homepageBundle, 'scienceExchange_imageTwoAltText') ?? '',
          }}
          description={t(homepageBundle, 'scienceExchange_description') ?? ''}
          title={t(homepageBundle, 'scienceExchange_title') ?? ''}
          subTitle={t(homepageBundle, 'scienceExchange_subTitle') ?? ''}
          theme="light"
          cta={{
            label: t(homepageBundle, 'scienceExchange_ctaLabel') ?? '',
            link: ROUTES.SCIENCE_EXCHANGE,
          }}
        />
      </PageSection>

      <PageSection theme="green-primary">
        <SmallBanner
          logo
          theme={SECTION_THEME.Dark}
          heading={t(homepageBundle, 'climatePledge_description') ?? ''}
          image={{
            url: `${imageRoot}/TCPglobalcomponent-2.png`,
            alt: t(homepageBundle, 'climatePledge_imageTwoAltText') ?? '',
          }}
          cta={{ label: t(homepageBundle, 'climatePledge_ctaLabel') ?? '', href: 'https://www.theclimatepledge.com' }}
        />
      </PageSection>
    </>
  );
};
