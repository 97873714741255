import { FunctionComponent } from 'react';

import { KatDropdown, KatOption } from '@amzn/katal-react';

import { Icon, IconType } from 'src/components/atoms';

import styles from './Dropdown.module.scss';

export interface DropdownOption {
  text: string;
  icon?: IconType;
}

interface DropdownProps extends KatDropdown.Props {
  options?: DropdownOption[];
  className?: string;
}

export const Dropdown: FunctionComponent<DropdownProps> = ({ options, className, ...props }) => (
  <KatDropdown className={className} {...props} richSelectionLabel>
    {options &&
      options?.map((option) => (
        <KatOption key={option.text} value={option.text}>
          <div data-testid="dropdown-option" className={styles.option}>
            {option.icon && <Icon name={option.icon} className={styles.icon} />}
            {option.text}
          </div>
        </KatOption>
      ))}
  </KatDropdown>
);
