import classNames from 'classnames';
import { useCallback, useEffect, useRef, type FunctionComponent } from 'react';

import { ExternalLink } from 'src/components/atoms/external-link/ExternalLink';
import { Heading, HEADING_SIZE } from 'src/components/atoms/heading/Heading';
import { Tagline } from 'src/components/atoms/tagline/Tagline';
import { DynamicListItem } from 'src/components/common/dynamic-list/DynamicList';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';

import styles from './DynamicItem.module.scss';

type DynamicListProps = DynamicListItem & {
  isFirst: boolean;
  isLast: boolean;
  isActive: boolean;
  onActiveChange: () => void;
};

// Component already tested in DynamicList.test.tsx suite
export const DynamicItem: FunctionComponent<DynamicListProps> = ({
  body,
  title,
  link,
  tagline,
  isActive,
  isFirst,
  isLast,
  onActiveChange,
}) => {
  const { isDesktop } = useDeviceTracker();

  const self = useRef<HTMLLIElement>({} as HTMLLIElement);

  const handleScroll = useCallback(() => {
    const distanceTopPx = self.current.getBoundingClientRect().top;
    const distanceBottomPx = window.innerHeight - self.current.getBoundingClientRect().bottom;

    const triggerOffset = isDesktop
      ? window.innerHeight / 2 - self.current.offsetHeight
      : window.innerHeight / 4 - self.current.offsetHeight;

    const shouldBeActive = (isLast || distanceTopPx > triggerOffset) && (isFirst || distanceBottomPx > triggerOffset);

    if (shouldBeActive) {
      onActiveChange();
    }
  }, [isFirst, isLast, onActiveChange, isDesktop]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [self, handleScroll]);

  return (
    <li ref={self} className={classNames(styles.dynamicItem, !isActive && styles.disabled)} data-testid="dynamicItem">
      {tagline && <Tagline className={styles.tagline}>{tagline}</Tagline>}
      <Heading className={styles.title} as="h3" size={HEADING_SIZE.h3}>
        {title}
      </Heading>
      <p className={styles.copy}>{body}</p>
      {link && (
        <ExternalLink
          className={styles.externalLink}
          iconName={link.icon}
          text={link.text}
          {...(link.onClickOverride && { onClick: link.onClickOverride })}
          {...(link.href && { link: link.href })}
          bold
          data-testid="dynamicItemLink"
        />
      )}
    </li>
  );
};
