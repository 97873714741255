import styled from 'styled-components';

import variables from '@amzn/sss-website-theme/dist/theme/variables.module.scss';

import { PageSection } from 'src/components/common';

const navbarHeightPx = '72px';
const filterSectionBackground =
  'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/gettingstarted-hero.jpg';

export const FilterBannerSection = styled(PageSection)`
  background:
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${filterSectionBackground}) bottom;
  background-position-y: -500px;
  padding: 1em 0;
  margin-bottom: -40px;
  background-size: cover;
  @media (min-width: 768px) {
    padding: 3em;
    margin-bottom: 0;
    background-position-y: center;
  }
  h1,
  p {
    color: ${variables.white} !important;
  }
`;

export const MeasurementSection = styled(PageSection)`
  background-color: ${variables.greyTertiary};
  padding: 30px;
  padding-bottom: 80px;

  /* Needed so that scroll to this section accounts for navbar size*/
  scroll-margin-top: ${navbarHeightPx};

  h2,
  p {
    color: ${variables.greenPrimary} !important;
  }

  @media (min-width: 1280px) {
    padding-bottom: 72px;
  }
`;

export const TakeActionSection = styled(PageSection)`
  background-color: ${variables.greySecondary};
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  /* -40px top padding needed for the border radius of this component to be visible*/
  margin-top: -40px;
  padding-top: 80px;
  /* Needed so that scroll to this section accounts for navbar size*/
  scroll-margin-top: ${navbarHeightPx};

  @media (min-width: 768px) {
    border-top-left-radius: ${variables.borderRadiusMedium};
    border-top-right-radius: ${variables.borderRadiusMedium};
    padding-top: 125px;
  }
`;

export const ScienceExchangeSection = styled(PageSection)`
  /* Needed so that scroll to this section accounts for navbar size*/
  scroll-margin-top: ${navbarHeightPx};

  background-color: ${variables.slateDark};
  padding: 30px;
  h2,
  p {
    color: ${variables.greenSecondary} !important;
  }
`;
