import { FC } from 'react';

import { KatToggle } from '@amzn/katal-react';

import './Toggle.module.scss';

interface ToggleProps extends KatToggle.Props {
  state: boolean;
}

/**
 * Toggle component to be used on ASX. Extends `KatToggle`
 * https://katal.amazon.dev/design-system/components/toggle/
 */
export const Toggle: FC<ToggleProps> = ({ state, ...props }) => (
  <KatToggle name={props.name} checked={state} onChange={props.onChange} data-testid={props.name} />
);
