import { useMemo } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { WEBLAB_NAME, WEBLAB_TREATMENT, getTreatment } from 'src/clients/weblab-client';
import { ACCORDION_VARIANT, IconType } from 'src/components/atoms';
import { ModalProps } from 'src/components/common';
import { AccordionsComponentContentProps } from 'src/components/common/accordions-component/accordions-component-content/AccordionsComponentContent';
import { SCIENCE_EXCHANGE_PAGE_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers';
import useDeviceSize from 'src/hooks/useDeviceSize.hook';

export enum OUR_APPROACH_STEPS {
  MODEL = 'Model',
  INVENT = 'Invent',
  DEVELOP = 'Develop',
  VALIDATE = 'Validate',
  SCALE = 'Scale',
}

export interface ScienceExchangeData {
  focusAreaContent: AccordionsComponentContentProps[];
  crossSectorContent: AccordionsComponentContentProps[];
  ourApproachModalContent: Record<OUR_APPROACH_STEPS, Partial<ModalProps>>;
}

const DPM_PATH = '/decarbonization-models';

const DEFAULT_MODAL_CONTENT = {
  title: '',
  subtitle: '',
  list: {
    heading: '',
    elements: [],
  },
};

export const useScienceExchangeData = () => {
  const [bundle, isBundleLoading] = useBundle(SCIENCE_EXCHANGE_PAGE_BUNDLE_NAME);
  const { isSmallDesktop } = useDeviceSize();

  // If on screen smaller than desktop or DPM weblab is turned off, don't render DPM copy/links
  const hideDpmOnScreen = !isSmallDesktop;
  const dpmWeblab = getTreatment(WEBLAB_NAME.ASX_DPM_LAUNCH);
  const showDpmContent = WEBLAB_TREATMENT.T1 === dpmWeblab && !hideDpmOnScreen;

  // TODO: Update placeholder links with actual Science Exchange links
  return useMemo(
    () =>
      isBundleLoading
        ? {
            focusAreaContent: [],
            crossSectorContent: [],
            ourApproachModalContent: {
              [OUR_APPROACH_STEPS.MODEL]: DEFAULT_MODAL_CONTENT,
              [OUR_APPROACH_STEPS.INVENT]: DEFAULT_MODAL_CONTENT,
              [OUR_APPROACH_STEPS.DEVELOP]: DEFAULT_MODAL_CONTENT,
              [OUR_APPROACH_STEPS.VALIDATE]: DEFAULT_MODAL_CONTENT,
              [OUR_APPROACH_STEPS.SCALE]: DEFAULT_MODAL_CONTENT,
            },
          }
        : {
            focusAreaContent: [
              {
                cardTitle: t(bundle, 'focusAreasPackagingMaterials_title') ?? '',
                cardSubtitle: t(bundle, 'focusAreasPackagingMaterials_description') ?? '',
                cardImage: {
                  src: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-packagingmaterials-1.png',
                  alt: 'Cardboard boxes',
                },
                icon: 'packaging' as IconType,
                accordions: [
                  {
                    title: t(bundle, 'focusAreasPaperSection_title') ?? '',
                    subtitle: t(bundle, 'focusAreasPaperSection_description') ?? '',
                    content: showDpmContent
                      ? [
                          {
                            subtitle: t(bundle, 'focusAreasPaperSection_modelTag') ?? '',
                            text: t(bundle, 'focusAreasPaperSection_modelDescription') ?? '',
                            links: [
                              {
                                label: t(bundle, 'focusAreasPaperSection_cta1') ?? '',
                                url: `${DPM_PATH}/paper`,
                              },
                            ],
                          },
                        ]
                      : [],
                  },
                  {
                    title: t(bundle, 'focusAreasPlasticsSection_title') ?? '',
                    subtitle: t(bundle, 'focusAreasPlasticsSection_description') ?? '',
                    content: showDpmContent
                      ? [
                          {
                            subtitle: t(bundle, 'focusAreasPlasticsSection_modelTag') ?? '',
                            text: t(bundle, 'focusAreasPlasticsSection_modelDescription') ?? '',
                            links: [
                              {
                                label: t(bundle, 'focusAreasPlasticsSection_cta1') ?? '',
                                url: `${DPM_PATH}/plastics`,
                              },
                            ],
                          },
                        ]
                      : [],
                  },
                  {
                    title: t(bundle, 'focusAreasInnovationActions_title') ?? '',
                    content: [
                      {
                        subtitle: t(bundle, 'focusAreasPackagingResource1_tag') ?? '',
                        text: t(bundle, 'focusAreasPackagingResource1_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-packagingmaterials-innovationactions-1.png',
                          alt: 'Plastic',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasPackagingResource1_cta1') ?? '',
                            url: 'https://www.amazon.science/blog/amazon-and-energy-dept-team-up-to-change-how-we-recycle-plastic',
                          },
                        ],
                      },
                      {
                        subtitle: t(bundle, 'focusAreasPackagingResource2_tag') ?? '',
                        text: t(bundle, 'focusAreasPackagingResource2_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-packagingmaterials-innovationactions-2.png',
                          alt: 'Bags',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasPackagingResource2_cta1') ?? '',
                            url: 'https://www.aboutamazon.eu/news/sustainability/how-amazon-is-innovating-to-come-up-with-the-packaging-of-the-future',
                          },
                        ],
                      },
                      {
                        subtitle: t(bundle, 'focusAreasPackagingResource3_tag') ?? '',
                        text: t(bundle, 'focusAreasPackagingResource3_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-packagingmaterials-innovationactions-3.png',
                          alt: 'People in a lab',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasPackagingResource3_cta1') ?? '',
                            url: 'https://www.aboutamazon.com/news/sustainability/why-amazons-climate-pledge-fund-is-investing-in-this-entrepreneur-and-her-plastic-waste-solution',
                          },
                        ],
                      },
                      {
                        subtitle: t(bundle, 'focusAreasPackagingResource4_tag') ?? '',
                        text: t(bundle, 'focusAreasPackagingResource4_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-packagingmaterials-innovationactions-4.png',
                          alt: 'Amazon Research Awards logo',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasPackagingResource4_cta1') ?? '',
                            url: 'https://www.amazon.science/research-awards/program-updates/79-amazon-research-awards-recipients-announced',
                          },
                        ],
                      },
                      {
                        subtitle: t(bundle, 'focusAreasPackagingResource5_tag') ?? '',
                        text: t(bundle, 'focusAreasPackagingResource5_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-packagingmaterials-innovationactions-5.png',
                          alt: 'People in a lab',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasPackagingResource5_cta1') ?? '',
                            url: 'https://www.aboutamazon.com/news/sustainability/amazon-climate-pledge-fund-ai-recycling',
                          },
                        ],
                      },
                      {
                        subtitle: t(bundle, 'focusAreasPackagingResource6_tag') ?? '',
                        text: t(bundle, 'focusAreasPackagingResource6_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-packagingmaterials-innovationactions-6.png',
                          alt: 'U.S. Capitol Building',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasPackagingResource6_cta1') ?? '',
                            url: 'https://www.amazon.science/blog/decarbonizing-paper-packaging',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                cardTitle: t(bundle, 'focusAreasBuildingMaterials_title') ?? '',
                cardSubtitle: t(bundle, 'focusAreasBuildingMaterials_description') ?? '',
                cardImage: {
                  src: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-buildingmaterials-1.png',
                  alt: 'Corporate office building',
                },
                icon: 'building' as IconType,
                accordions: [
                  {
                    title: t(bundle, 'focusAreasConcreteSection_title') ?? '',
                    subtitle: t(bundle, 'focusAreasConcreteSection_description') ?? '',
                    content: showDpmContent
                      ? [
                          {
                            subtitle: t(bundle, 'focusAreasConcreteSection_modelTag') ?? '',
                            text: t(bundle, 'focusAreasConcreteSection_modelDescription') ?? '',
                            links: [
                              {
                                label: t(bundle, 'focusAreasConcreteSection_cta1') ?? '',
                                url: `${DPM_PATH}/concrete`,
                              },
                            ],
                          },
                        ]
                      : [],
                  },
                  {
                    title: t(bundle, 'focusAreasSteelSection_title') ?? '',
                    subtitle: t(bundle, 'focusAreasSteelSection_description') ?? '',
                    content: showDpmContent
                      ? [
                          {
                            subtitle: t(bundle, 'focusAreasSteelSection_modelTag') ?? '',
                            text: t(bundle, 'focusAreasSteelSection_modelDescription') ?? '',
                            links: [
                              {
                                label: t(bundle, 'focusAreasSteelSection_cta1') ?? '',
                                url: `${DPM_PATH}/steel`,
                              },
                            ],
                          },
                        ]
                      : [],
                  },
                  {
                    title: t(bundle, 'focusAreasInnovationActions_title') ?? '',
                    content: [
                      {
                        subtitle: t(bundle, 'focusAreasBuildingResource1_tag') ?? '',
                        text: t(bundle, 'focusAreasBuildingResource1_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-buildingmaterials-innovationactions-1.png',
                          alt: 'Climate Pledge Fund logo',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasBuildingResource1_cta1') ?? '',
                            url: 'https://www.businesswire.com/news/home/20220428005472/en/Brimstone-to-Produce-the-World%E2%80%99s-First-Carbon-Negative-Portland-Cement',
                          },
                        ],
                      },
                      {
                        subtitle: t(bundle, 'focusAreasBuildingResource2_tag') ?? '',
                        text: t(bundle, 'focusAreasBuildingResource2_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-buildingmaterials-innovationactions-2.png',
                          alt: 'Corporate office buildings',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasBuildingResource2_cta1') ?? '',
                            url: 'https://press.aboutamazon.com/2020/9/amazon-announces-first-recipients-of-investments-from-2-billion-climate-pledge-fund',
                          },
                          {
                            label: t(bundle, 'focusAreasBuildingResource2_cta2') ?? '',
                            url: 'https://www.aboutamazon.com/news/sustainability/amazon-hq2-sustainable-construction-design',
                          },
                        ],
                      },
                      {
                        subtitle: t(bundle, 'focusAreasBuildingResource3_tag') ?? '',
                        text: t(bundle, 'focusAreasBuildingResource3_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-buildingmaterials-innovationactions-3.png',
                          alt: 'Cement trucks',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasBuildingResource3_cta1') ?? '',
                            url: 'https://www.aboutamazon.com/news/sustainability/aws-decarbonizing-construction-data-centers',
                          },
                        ],
                      },
                      {
                        subtitle: t(bundle, 'focusAreasBuildingResource4_tag') ?? '',
                        text: t(bundle, 'focusAreasBuildingResource4_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-buildingmaterials-innovationactions-4.png',
                          alt: 'Climate Pledge Fund logo',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasBuildingResource4_cta1') ?? '',
                            url: 'https://www.greenbiz.com/article/green-steel-innovator-electra-scores-85m-backing',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                cardTitle: t(bundle, 'focusAreasAlternativeFuels_title') ?? '',
                cardSubtitle: t(bundle, 'focusAreasAlternativeFuels_description') ?? '',
                cardImage: {
                  src: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-alternativefuels-1.png',
                  alt: 'An aerial view of oil storage tanks',
                },
                icon: 'alternativeFuels' as IconType,
                accordions: [
                  {
                    title: t(bundle, 'focusAreasDropInFuelsSection_title') ?? '',
                    subtitle: t(bundle, 'focusAreasDropInFuelsSection_description') ?? '',
                    content: showDpmContent
                      ? [
                          {
                            subtitle: t(bundle, 'focusAreasDropInFuelsSection_modelTag') ?? '',
                            text: t(bundle, 'focusAreasDropInFuelsSection_modelDescription') ?? '',
                            links: [
                              {
                                label: t(bundle, 'focusAreasDropInFuelsSection_cta1') ?? '',
                                url: `${DPM_PATH}/fuels`,
                              },
                            ],
                          },
                        ]
                      : [],
                  },
                  {
                    title: t(bundle, 'focusAreasHydrogenSection_title') ?? '',
                    subtitle: t(bundle, 'focusAreasHydrogenSection_description') ?? '',
                    content: showDpmContent
                      ? [
                          {
                            subtitle: t(bundle, 'focusAreasHydrogenSection_modelTag') ?? '',
                            text: t(bundle, 'focusAreasHydrogenSection_modelDescription') ?? '',
                            links: [
                              {
                                label: t(bundle, 'focusAreasHydrogenSection_cta1') ?? '',
                                url: `${DPM_PATH}/hydrogen`,
                              },
                            ],
                          },
                        ]
                      : [],
                  },
                  {
                    title: t(bundle, 'focusAreasInnovationActions_title') ?? '',
                    content: [
                      {
                        subtitle: t(bundle, 'focusAreasFuelsResource1_tag') ?? '',
                        text: t(bundle, 'focusAreasFuelsResource1_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-alternativefuels-innovationactions-1.jpg',
                          alt: 'Amazon Research Awards logo',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasFuelsResource1_cta1') ?? '',
                            url: 'https://www.amazon.science/research-awards/program-updates/79-amazon-research-awards-recipients-announced',
                          },
                          {
                            label: t(bundle, 'focusAreasFuelsResource1_cta2') ?? '',
                            url: 'https://www.amazon.science/research-awards/program-updates/99-amazon-research-awards-recipients-announced',
                          },
                        ],
                      },
                      {
                        subtitle: t(bundle, 'focusAreasFuelsResource2_tag') ?? '',
                        text: t(bundle, 'focusAreasFuelsResource2_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-alternativefuels-innovationactions-2.jpg',
                          alt: 'Cargo ship',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasFuelsResource2_cta1') ?? '',
                            url: 'https://www.cozev.org/initiativesfeed/join-zero-emission-maritime-buyers-alliance',
                          },
                        ],
                      },
                      {
                        subtitle: t(bundle, 'focusAreasFuelsResource3_tag') ?? '',
                        text: t(bundle, 'focusAreasFuelsResource3_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-alternativefuels-innovationactions-3.jpg',
                          alt: 'Airplane',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasFuelsResource3_cta1') ?? '',
                            url: 'https://www.aboutamazon.com/news/sustainability/amazon-air-joins-the-sustainable-aviation-buyers-alliance-saba',
                          },
                        ],
                      },
                      {
                        subtitle: t(bundle, 'focusAreasFuelsResource4_tag') ?? '',
                        text: t(bundle, 'focusAreasFuelsResource4_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-alternativefuels-innovationactions-4.jpg',
                          alt: 'Infinium e fuels plant',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasFuelsResource4_cta1') ?? '',
                            url: 'https://www.aboutamazon.com/news/sustainability/amazon-invests-in-infinium-to-support-decarbonization-efforts-in-transportation-sector',
                          },
                          {
                            label: t(bundle, 'focusAreasFuelsResource4_cta2') ?? '',
                            url: 'https://www.aboutamazon.com/news/sustainability/amazon-is-partnering-with-infinium-to-help-power-its-trucking-fleet-with-low-carbon-electrofuels',
                          },
                        ],
                      },
                      {
                        subtitle: t(bundle, 'focusAreasFuelsResource5_tag') ?? '',
                        text: t(bundle, 'focusAreasFuelsResource5_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-alternativefuels-innovationactions-5.jpg',
                          alt: 'People in a lab',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasFuelsResource5_cta1') ?? '',
                            url: 'https://www.aboutamazon.com/news/sustainability/amazon-invests-in-green-hydrogen-companies',
                          },
                          {
                            label: t(bundle, 'focusAreasFuelsResource5_cta2') ?? '',
                            url: 'https://www.axios.com/pro/climate-deals/2024/02/09/koloma-245-million-hydrogen-khosla-amazon-united',
                          },
                          {
                            label: t(bundle, 'focusAreasFuelsResource5_cta3') ?? '',
                            url: 'https://www.verneh2.com/about',
                          },
                        ],
                      },
                      {
                        subtitle: t(bundle, 'focusAreasFuelsResource6_tag') ?? '',
                        text: t(bundle, 'focusAreasFuelsResource6_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-alternativefuels-innovationactions-6.jpg',
                          alt: 'Fullfillment center',
                        },
                        links: [
                          {
                            label: t(bundle, 'focusAreasFuelsResource6_cta1') ?? '',
                            url: 'https://www.ir.plugpower.com/press-releases/news-details/2023/Plug-Power-Completes-First-Installation-of-Electrolyzer-at-Amazon-Fulfillment-Center/default.aspx',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            crossSectorContent: [
              {
                variant: 'reduced',
                cardTitle: t(bundle, 'crossSectorSolutions_title') ?? '',
                cardSubtitle: t(bundle, 'crossSectorSolutions_description') ?? '',
                cardImage: {
                  src: '',
                  alt: 'paper packaging',
                },
                icon: 'download' as IconType,
                accordions: [
                  {
                    variant: ACCORDION_VARIANT.AccordionComponent,
                    title: t(bundle, 'crossSectorMeasurementScience_title') ?? '',
                    content: [
                      {
                        text: t(bundle, 'crossSectorMeasurementScience_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-crosssectorsolutions-1.jpg',
                          alt: 'Amazon Research Awards logo',
                        },
                        links: [
                          {
                            label: t(bundle, 'crossSectorMeasurementScience_cta1') ?? '',
                            url: 'https://www.amazon.science/research-awards/',
                          },
                          {
                            label: t(bundle, 'crossSectorMeasurementScience_cta2') ?? '',
                            url: 'https://www.amazon.science/research-awards/call-for-proposals/sustainability-call-for-proposals-spring-2024',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: t(bundle, 'crossSectorComputationalMethods_title') ?? '',
                    content: [
                      {
                        text: t(bundle, 'crossSectorComputationalMethods_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-crosssectorsolutions-2.jpg',
                          alt: 'Amazon Science logo',
                        },
                        links: [
                          {
                            label: t(bundle, 'crossSectorComputationalMethods_cta1') ?? '',
                            url: 'https://www.amazon.science/publications/flamingo-environmental-impact-factor-matching-for-life-cycle-assessment-with-zero-shot-machine-learning',
                          },
                          {
                            label: t(bundle, 'crossSectorComputationalMethods_cta2') ?? '',
                            url: 'https://aws.amazon.com/solutions/guidance/environmental-impact-factor-mapping-on-aws/',
                          },
                          {
                            label: t(bundle, 'crossSectorComputationalMethods_cta3') ?? '',
                            url: 'https://github.com/amazon-science/carbon-assessment-with-ml',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: t(bundle, 'crossSectorAsdi_title') ?? '',
                    content: [
                      {
                        text: t(bundle, 'crossSectorAsdi_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-crosssectorsolutions-3.jpg',
                          alt: 'A person in a lab',
                        },
                        links: [
                          {
                            label: t(bundle, 'crossSectorAsdi_cta1') ?? '',
                            url: 'https://registry.opendata.aws/collab/asdi/',
                          },
                          {
                            label: t(bundle, 'crossSectorAsdi_cta2') ?? '',
                            url: 'https://aws.amazon.com/opendata/open-data-sponsorship-program/',
                          },
                          {
                            label: t(bundle, 'crossSectorAsdi_cta3') ?? '',
                            url: 'https://registry.opendata.aws/tag/sustainability/usage-examples/index.html',
                          },
                          {
                            label: t(bundle, 'crossSectorAsdi_cta4') ?? '',
                            url: 'https://aws.amazon.com/government-education/sustainability-research-credits/',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    title: t(bundle, 'crossSectorAccelerators_title') ?? '',
                    content: [
                      {
                        text: t(bundle, 'crossSectorAcceleratorsResource1_description') ?? '',
                        image: {
                          url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-crosssectorsolutions-4.jpg',
                          alt: 'A group of people',
                        },
                        links: [
                          {
                            label: t(bundle, 'crossSectorAcceleratorsResource1_cta') ?? '',
                            url: 'https://aws.amazon.com/blogs/startups/aws-and-ircai-announce-winners-of-the-2023-compute-for-climate-fellowship-and-open-applications-for-2024/#:~:text=The%20Compute%20for%20Climate%20Fellowship%20is%20a%20first%2Dof%2Dits,quickly%20address%20the%20climate%20crisis',
                          },
                        ],
                      },
                      {
                        text: t(bundle, 'crossSectorAcceleratorsResource2_description') ?? '',
                        links: [
                          {
                            label: t(bundle, 'crossSectorAcceleratorsResource2_cta') ?? '',
                            url: 'https://sell.amazon.co.uk/programmes/sustainability-accelerator#:~:text=The%20Compute%20for%20Climate%20Fellowship%20is%20a%20first%2Dof%2Dits,quickly%20address%20the%20climate%20crisis',
                          },
                        ],
                      },
                      {
                        text: t(bundle, 'crossSectorAcceleratorsResource3_description') ?? '',
                        links: [
                          {
                            label: t(bundle, 'crossSectorAcceleratorsResource3_cta') ?? '',
                            url: 'https://aws.amazon.com/energy-utilities/clean-energy-accelerator/',
                          },
                        ],
                      },
                      {
                        text: t(bundle, 'crossSectorAcceleratorsResource4_description') ?? '',
                      },
                    ],
                  },
                ],
              },
            ],
            ourApproachModalContent: {
              [OUR_APPROACH_STEPS.MODEL]: {
                title: t(bundle, 'ourApproachModel_title'),
                subtitle: t(bundle, 'ourApproachModel_description'),
                list: {
                  heading: t(bundle, 'ourApproachModalItemsHeading'),
                  elements: [
                    t(bundle, 'ourApproachModelModalItem1_label') ?? '',
                    t(bundle, 'ourApproachModelModalItem2_label') ?? '',
                    t(bundle, 'ourApproachModelModalItem3_label') ?? '',
                  ],
                },
              },
              [OUR_APPROACH_STEPS.INVENT]: {
                title: t(bundle, 'ourApproachInvent_title'),
                subtitle: t(bundle, 'ourApproachInvent_description'),
                list: {
                  heading: t(bundle, 'ourApproachModalItemsHeading'),
                  elements: [
                    t(bundle, 'ourApproachInventModalItem1_label') ?? '',
                    t(bundle, 'ourApproachInventModalItem2_label') ?? '',
                    t(bundle, 'ourApproachInventModalItem3_label') ?? '',
                  ],
                },
              },
              [OUR_APPROACH_STEPS.DEVELOP]: {
                title: t(bundle, 'ourApproachDevelop_title'),
                subtitle: t(bundle, 'ourApproachDevelop_description'),
                list: {
                  heading: t(bundle, 'ourApproachModalItemsHeading'),
                  elements: [
                    t(bundle, 'ourApproachDevelopModalItem1_label') ?? '',
                    t(bundle, 'ourApproachDevelopModalItem2_label') ?? '',
                    t(bundle, 'ourApproachDevelopModalItem3_label') ?? '',
                  ],
                },
              },
              [OUR_APPROACH_STEPS.VALIDATE]: {
                title: t(bundle, 'ourApproachValidate_title'),
                subtitle: t(bundle, 'ourApproachValidate_description'),
                list: {
                  heading: t(bundle, 'ourApproachModalItemsHeading'),
                  elements: [
                    t(bundle, 'ourApproachValidateModalItem1_label') ?? '',
                    t(bundle, 'ourApproachValidateModalItem2_label') ?? '',
                  ],
                },
              },
              [OUR_APPROACH_STEPS.SCALE]: {
                title: t(bundle, 'ourApproachScale_title'),
                subtitle: t(bundle, 'ourApproachScale_description'),
                list: {
                  heading: t(bundle, 'ourApproachModalItemsHeading'),
                  elements: [
                    t(bundle, 'ourApproachScaleModalItem1_label') ?? '',
                    t(bundle, 'ourApproachScaleModalItem2_label') ?? '',
                    t(bundle, 'ourApproachScaleModalItem3_label') ?? '',
                  ],
                },
              },
            },
          },
    [bundle, isBundleLoading, showDpmContent]
  );
};
