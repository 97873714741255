import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { DisplayType, SectionType } from '@amzn/coral_com-amazon-sssapinterface-model';
import { useBundle } from '@amzn/react-arb-tools';

import { CardProps } from 'src/components/common';
import { TeaserCardImageProps } from 'src/components/common/teaser-card/TeaserCardImage';
import {
  FOCUS_AREA_URL_BY_PAGE_ID,
  FOCUS_AREA_PAGE_ID,
  FOCUS_AREA_URL,
} from 'src/components/pages/resource-pages/focus-areas/focusAreaPath';
import { SECONDARY_HERO_RESOURCE_BUNDLE_NAME } from 'src/constants';
import { ROUTES } from 'src/data/enums/Routes';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { FocusAreaPage } from 'src/fixtures/structures/pages';
import { extractAssetBySectionAndDisplay, getResourcesBySectionType, t } from 'src/helpers';
import { useCommonStrings } from 'src/hooks/useCommonStrings';
import useDeviceSize from 'src/hooks/useDeviceSize.hook';

const focusAreas = Object.values(FocusAreaPage);

export const useFocusAreaCards = () => {
  const [secondaryHeroBundle] = useBundle(SECONDARY_HERO_RESOURCE_BUNDLE_NAME);
  const { isSmallDesktop } = useDeviceSize();

  return useMemo<CardProps[]>(
    () =>
      focusAreas.map((focusArea) => {
        const { sections } = focusArea;
        const secondaryHero = getResourcesBySectionType(sections, SectionType.SECONDARY_HERO)[0];
        const focusAreaLink = `${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL_BY_PAGE_ID[focusArea.id]}`;

        const cardProps: CardProps = {
          theme: SECTION_THEME.Dark,
          title: t(secondaryHeroBundle, secondaryHero.display?.name) ?? '',
          subtitle: t(secondaryHeroBundle, secondaryHero.display?.shortDescription) ?? '',
          image: `https://dn838xj7iw6s8.cloudfront.net${extractAssetBySectionAndDisplay(sections, SectionType.SECONDARY_HERO, isSmallDesktop ? DisplayType.STANDARD : DisplayType.MOBILE)}`,
          alt: secondaryHero.assets.primaryAsset?.[0].altText,
          link: {
            label: 'Explore resources',
            url: focusAreaLink,
          },
          icon: 'chevronRight',
          imagePosition: focusArea.id === 'transportation' ? 'right' : 'center',
          target: '_self',
        };
        return cardProps;
      }),
    [isSmallDesktop, secondaryHeroBundle]
  );
};

export const useFocusAreaTeaser = () => {
  const [secondaryHeroBundle] = useBundle(SECONDARY_HERO_RESOURCE_BUNDLE_NAME);
  const { commonBundle } = useCommonStrings();
  const { focusAreaUrl } = useParams();
  const focusAreaId = FOCUS_AREA_PAGE_ID[focusAreaUrl as FOCUS_AREA_URL];

  return useMemo<TeaserCardImageProps[]>(() => {
    // Find the index of the current focusAreaUrl
    const currentIndex = focusAreas.findIndex((card) => card.id === focusAreaId);
    const pagesLength = focusAreas.length;

    // Create a new array with the one before, and one after the current page
    const filteredFocusAreas = [
      focusAreas[currentIndex === 0 ? pagesLength - 1 : currentIndex - 1],
      focusAreas[currentIndex === pagesLength - 1 ? 0 : currentIndex + 1],
    ];

    return filteredFocusAreas
      .map((focusArea): TeaserCardImageProps | null => {
        if (!focusArea) return null;

        const { sections } = focusArea;
        const secondaryHeroSection = getResourcesBySectionType(sections, SectionType.SECONDARY_HERO);
        const secondaryHero = secondaryHeroSection?.[0];
        if (!secondaryHero) return null;

        const focusAreaLink = `${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL_BY_PAGE_ID[focusArea.id]}`;

        const teaserProps: TeaserCardImageProps = {
          cardTitle: t(secondaryHeroBundle, secondaryHero.display?.name) ?? '',
          cta: t(commonBundle, 'learnMore_label') ?? '',
          image: `https://dn838xj7iw6s8.cloudfront.net${extractAssetBySectionAndDisplay(sections, SectionType.SECONDARY_HERO, DisplayType.MOBILE)}`,
          linkUrl: focusAreaLink,
          alt: secondaryHero.assets.primaryAsset?.[0].altText,
        };

        return teaserProps;
      })
      .filter((teaser): teaser is TeaserCardImageProps => teaser !== null);
  }, [commonBundle, focusAreaId, secondaryHeroBundle]);
};
