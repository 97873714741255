import classNames from 'classnames';
import { FunctionComponent, HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { Icon } from 'src/components/atoms/icon/Icon';
import AssetPill from 'src/components/common/asset-pill/AssetPill';
import { ASSET_GRAPHIC_MESSAGE_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers/translation-helpers';

import styles from './AssetGraphic.module.scss';
import { playFillGraphicAnimation, playGraphicOutAnimation, sleepMs, resetToBeginning } from './assetGraphic.anim';
import GraphicCircle from './graphic-circle/GraphicCircle';

interface AssetGraphicProps extends HTMLAttributes<HTMLDivElement> {}

// Percentage of progress of each step
const STEPS = [0.33, 0.68, 1];

const AssetGraphic: FunctionComponent<AssetGraphicProps> = ({ className }) => {
  const [assetGraphicBundle] = useBundle(ASSET_GRAPHIC_MESSAGE_BUNDLE_NAME);

  const [activeStep, setActiveStep] = useState(0);
  const [activePill, setActivePill] = useState(-1);
  const [graphicIsVisible, setGraphicIsVisible] = useState(true);
  const [animationIsPlaying, setAnimationIsPlaying] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);

  const graphicRef = useRef<SVGSVGElement>(null);
  const self = useRef<HTMLDivElement>(null);

  const progress = useMemo(() => STEPS[activeStep], [activeStep]);
  const isLastStep = useMemo(() => activeStep === 2, [activeStep]);
  const isInitialPill = useMemo(() => activePill === -1, [activePill]);

  const graphic = useMemo(
    () => graphicRef.current?.querySelector?.('#js-graphic') as SVGCircleElement,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [graphicRef.current]
  );

  const raisedCircles = useMemo(
    () => graphicRef.current?.querySelectorAll?.('#js-raised-circle') as unknown as SVGCircleElement[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [graphicRef.current]
  );

  const enableAnimation = useCallback(() => {
    setGraphicIsVisible(true);
  }, [setGraphicIsVisible]);

  const disableAnimation = useCallback(() => {
    // Cleanup so everything is ready when component gets back in view
    if (!self.current || !graphic) return;

    resetToBeginning(self.current, graphic, raisedCircles);

    setGraphicIsVisible(false);
    setActiveStep(0);
    setActivePill(-1);
    setAnimationIsPlaying(true);
  }, [graphic, raisedCircles]);

  const goToNextStep = () => {
    setActiveStep((step) => (step + 1) % STEPS.length);
  };

  const handleSlideshowEnd = async () => {
    // Await a bit before playing the out animation to restart the cycle
    if (!self.current || !graphic || !raisedCircles) return;

    await sleepMs(1000, self.current);

    setActivePill(0);
    playGraphicOutAnimation(graphic, raisedCircles, () => {
      goToNextStep();
      setAnimationIsPlaying(false);
    });
  };

  const handleSlideEnd = async () => {
    if (!self.current) return;
    // Await for a bit after the step animation completed so the user can see the active pill
    await sleepMs(2000, self.current);
    goToNextStep();
    setAnimationIsPlaying(false);
  };

  const playGoToNextSlide = async () => {
    if (!graphic || !raisedCircles) return;

    const circle = raisedCircles[activeStep];

    if (!isLastStep) setActivePill((pill) => (pill + 1) % STEPS.length);

    setAnimationIsPlaying(true);
    playFillGraphicAnimation(graphic, circle, progress, isLastStep ? handleSlideshowEnd : handleSlideEnd);
  };

  const handleTogglePlay = () => {
    setIsPlaying((playing) => !playing);
  };

  useEffect(() => {
    if (!graphicIsVisible || isInitialPill || !isPlaying || animationIsPlaying) return;

    playGoToNextSlide();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, graphicIsVisible, isPlaying, animationIsPlaying]);

  useEffect(() => {
    const triggerAnimn = async () => {
      if (!isInitialPill || !graphicIsVisible || !self.current) return;

      // Only for the first pill we await so the user
      // can see the 01 pill active for a bit
      await sleepMs(2000, self.current);
      setAnimationIsPlaying(true);
      setActivePill(0);

      await sleepMs(2000, self.current);
      setAnimationIsPlaying(false);
    };
    triggerAnimn();
  }, [isInitialPill, graphicIsVisible]);

  useEffect(() => {
    // Use two different observers so we can trigger actions at different threshold values
    const disableObserver = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) disableAnimation();
      },
      {
        threshold: 0,
      }
    );

    const enableObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) enableAnimation();
      },
      {
        threshold: 0.5,
      }
    );

    if (self.current) {
      enableObserver.observe(self.current);
      disableObserver.observe(self.current);
    }

    return () => {
      enableObserver.disconnect();
      disableObserver.disconnect();
    };
  }, [self, enableAnimation, disableAnimation]);

  return (
    <div ref={self} className={classNames(styles.assetGraphic, className)} id={styles.assetBanner}>
      <div className={styles.principal}>
        <AssetPill
          className={styles.firstPill}
          isActive={activePill === -1}
          pill="01"
          content={t(assetGraphicBundle, 'assetBanner-pill1_content')}
        />
        <Icon className={styles.arrowDown} name="arrowDown" />
        <AssetPill
          className={styles.secondPill}
          isActive={activePill === 0}
          pill="02"
          content={t(assetGraphicBundle, 'assetBanner-pill2_content')}
        />
        <GraphicCircle ref={graphicRef} />
      </div>
      <div className={styles.lateralPills}>
        <AssetPill
          className={styles.fourthPill}
          isActive={activePill === 2}
          pill="04"
          content={t(assetGraphicBundle, 'assetBanner-pill4_content')}
        />
        <AssetPill
          className={styles.thirdPill}
          isActive={activePill === 1}
          pill="03"
          content={t(assetGraphicBundle, 'assetBanner-pill3_content')}
        />
      </div>
      <button className={styles.pauseCta} type="button" onClick={handleTogglePlay}>
        {t(assetGraphicBundle, isPlaying ? 'assetBanner_pause' : 'assetBanner_play')}
      </button>
    </div>
  );
};

export default AssetGraphic;
