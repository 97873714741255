import { ReactElement, forwardRef } from 'react';

import { Heading, HEADING_SIZE } from 'src/components/atoms/heading/Heading';
import { DynamicListItem } from 'src/components/common/dynamic-list/DynamicList';
import styles from 'src/components/common/dynamic-list/DynamicList.module.scss';
import { DynamicListSVG } from 'src/components/common/dynamic-list/DynamicListSVG';

type MobileHeaderProps = {
  heading: string;
  subtitle: string;
  activeSlide: number;
  items: Array<DynamicListItem>;
};

// This dummy component exists to improve code readability
// it is tested on DynamicList.test.tsx suite
const MobileHeader = forwardRef<HTMLDivElement, MobileHeaderProps>(
  ({ heading, subtitle, activeSlide, items }, ref): ReactElement => (
    <>
      <Heading className={styles.heading} as="h2" size={HEADING_SIZE.h2}>
        {heading}
      </Heading>
      <p className={styles.subtitle}>{subtitle}</p>
      <div className={styles.svgMobile}>
        {DynamicListSVG && <DynamicListSVG activeSlide={activeSlide} />}
        <div ref={ref} className={styles.imageContainer}>
          <p className={styles.pageIndicator}>{`${activeSlide + 1} / ${items.length}`}</p>
        </div>
      </div>
    </>
  )
);

MobileHeader.displayName = 'MobileHeader';

export default MobileHeader;
