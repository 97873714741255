import classNames, { type Argument } from 'classnames';
import { FunctionComponent, HTMLProps } from 'react';

import { icons, IconType } from 'src/components/atoms/icons/icons';

import styles from './Icon.module.scss';

export type IconProps = HTMLProps<HTMLDivElement> & {
  name: IconType;
  className?: Argument;
};

export const Icon: FunctionComponent<IconProps> = ({ name, className }) => {
  const SelectedIcon = icons[name];

  return (
    <div data-testid="icon" className={classNames(styles.icon, className)}>
      {SelectedIcon && <SelectedIcon />}
    </div>
  );
};
