import { FunctionComponent, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { MessageBundle } from '@amzn/arb-tools';
import variables from '@amzn/sss-website-theme/dist/theme/variables.module.scss';

import { PRIMARY_BUTTON_SIZE, PrimaryButton } from 'src/components/atoms/primary-button/PrimaryButton';
import { BACK_BUTTON_LABEL } from 'src/constants';
import { t } from 'src/helpers/translation-helpers';
import useDeviceSize from 'src/hooks/useDeviceSize.hook';

interface BackButtonProps {
  searchBundle: MessageBundle;
}

const StyledMobileButton = styled(PrimaryButton)`
  width: 24px !important;
  height: 24px !important;
  color: ${variables.white} !important;
`;

// TODO add unit tests https://app.asana.com/0/1206972973144714/1207625704288113/f
export const BackButton: FunctionComponent<BackButtonProps> = ({ searchBundle }: BackButtonProps) => {
  const navigate = useNavigate();
  const referrer = useRef<string | null>(null);
  const { isSmallDesktop } = useDeviceSize();

  useEffect(() => {
    // Check if the current path is not '/search' && Record the referrer if the first access to this path
    if (window.location.pathname !== '/search' && !referrer.current) {
      referrer.current = document.referrer;
    }
  }, []);

  const handleBackClick = () => {
    // If there's a referrer from internal site, navigate to the previous page in the site
    if (referrer.current && referrer.current.startsWith(window.location.origin)) {
      navigate(-1);
    } else {
      // Otherwise, navigate to the home site
      navigate('/');
    }
  };

  const StyledButton = isSmallDesktop ? PrimaryButton : StyledMobileButton;

  return (
    <StyledButton
      buttonSize={PRIMARY_BUTTON_SIZE.Small}
      icon="chevronLeft"
      label={isSmallDesktop ? t(searchBundle, BACK_BUTTON_LABEL) : undefined}
      theme={isSmallDesktop ? 'dark' : 'light'}
      variant={isSmallDesktop ? 'inverted' : 'default'}
      onClick={handleBackClick}
      iconPosition="before"
    />
  );
};
