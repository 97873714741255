import { FunctionComponent } from 'react';
import { useParams, Navigate } from 'react-router-dom';

import { Resource, ResourceType } from '@amzn/coral_com-amazon-sssapinterface-model';
import { KatProgress } from '@amzn/katal-react';

import { Card, PageSection, Slider } from 'src/components/common';
import { ResourceTemplate } from 'src/components/pages/resource-pages/ResourceTemplate';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { Pages } from 'src/fixtures/structures/pages';
import { t } from 'src/helpers';
import { formatUrl } from 'src/helpers/formatting-helpers';
import { useCommonStrings } from 'src/hooks/useCommonStrings';

import { FOCUS_AREA_PAGE_ID, FOCUS_AREA_URL } from './focusAreaPath';
import { useFocusAreaSections } from './useFocusAreaSections';

export const FocusAreaPage: FunctionComponent = () => {
  const { focusAreaUrl } = useParams();
  const { getCtaByResource } = useCommonStrings();

  const focusAreaId = FOCUS_AREA_PAGE_ID[focusAreaUrl as FOCUS_AREA_URL];

  const page = Pages[focusAreaId];

  const {
    isBundleLoading,
    commonBundle,
    focusAreaBundle,
    collaboratorsSection,
    collaboratorsResources,
    collaboratorsBundle,
  } = useFocusAreaSections(page);

  if (!page) {
    return <Navigate to="/404" />;
  }

  if (isBundleLoading || !focusAreaBundle) {
    return <KatProgress indeterminate type="circular" />;
  }

  return (
    <ResourceTemplate page={page} mainBundle={focusAreaBundle}>
      {collaboratorsSection && (
        <PageSection data-anchor id={t(commonBundle, 'collaboratorsAnchor_id')}>
          <Slider
            key={`collaborators-slider-${page.id}`}
            size="small"
            theme={SECTION_THEME.Light}
            heading={t(focusAreaBundle, collaboratorsSection.display?.title)}
            description={t(focusAreaBundle, collaboratorsSection.display?.shortDescription)}
          >
            {collaboratorsResources.map((resource: Resource) => (
              <Card
                theme={SECTION_THEME.Light}
                key={resource.display?.name}
                image={formatUrl(resource.assets.secondaryAssets?.thumbnail?.[0].assetUrl)}
                alt={resource.assets.secondaryAssets?.thumbnail?.[0].altText}
                title={t(collaboratorsBundle, resource.display?.title)}
                subtitle={t(collaboratorsBundle, resource.display?.shortDescription)}
                icon="chevronRight"
                link={{
                  label: getCtaByResource(resource.resourceType as ResourceType).label,
                  url: formatUrl(resource.assets.primaryAsset[0].assetUrl),
                }}
              />
            ))}
          </Slider>
        </PageSection>
      )}
    </ResourceTemplate>
  );
};
