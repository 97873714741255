import classNames from 'classnames';
import { Children, FunctionComponent, PropsWithChildren, useRef, useState } from 'react';

import { KatCarousel, KatCarouselItem, KatCarouselItems, KatProgress } from '@amzn/katal-react';

import { PrimaryButton, PRIMARY_BUTTON_SIZE } from 'src/components/atoms';
import { TextComponent } from 'src/components/common';
import { SLIDER_PAGINATION_LABEL } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';
import { useCommonStrings } from 'src/hooks/useCommonStrings';
import useDeviceSize from 'src/hooks/useDeviceSize.hook';
import { CtaAttributes } from 'src/interfaces/CommonAttributes';

import styles from './Slider.module.scss';

interface SliderCardProps extends PropsWithChildren {
  className?: string;
  heading?: string;
  description?: string;
  cta?: CtaAttributes;
  theme?: SECTION_THEME;
  size?: 'small' | 'medium' | 'medium-large' | 'large' | 'xlarge';
  displayContents?: boolean;
  headingSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const KATAL_GRID_SIZE = 12;

// Helper method that splits an given array into smaller array "chunks".
const chunkArrayElements = (array: any[], chunkSize: number): any[][] =>
  array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!resultArray[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

export const Slider: FunctionComponent<SliderCardProps> = ({
  className,
  children,
  heading,
  description,
  cta,
  theme = SECTION_THEME.Light,
  size = 'medium',
  displayContents,
  headingSize = 'h2',
}) => {
  const { isMediumDesktop, isLargeDevice } = useDeviceSize();
  const { commonBundle } = useCommonStrings();

  // Calculate how many elements to show per slide based on device size.
  let itemsPerSlide: number;
  if (isMediumDesktop) {
    itemsPerSlide = 3;
  } else if (isLargeDevice) {
    itemsPerSlide = 2;
  } else {
    itemsPerSlide = 1;
  }
  const elementGridSize = KATAL_GRID_SIZE / itemsPerSlide;

  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef<KatCarousel.Element>(null);

  const chunkedChildren = chunkArrayElements(Children.toArray(children), itemsPerSlide);
  const slideCount = chunkedChildren.length;
  const onFirstSlide = currentSlide === 0;
  const onLastSlide = currentSlide === slideCount - 1;
  const totalElements = Children.toArray(children).length;
  const currentSlideElements = Math.min((currentSlide + 1) * itemsPerSlide, totalElements);

  // Invoking KatCarousel's built in goToNextItem() and goToPreviousItem() methods.
  // https://katal.amazon.dev/design-system/components/carousel/?example=Shoveler&tab-info=development
  const handleTransition = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const action = e.currentTarget.name;
    if (action === 'next' && !onLastSlide) {
      carouselRef.current?.goToNextItem();
      setCurrentSlide(currentSlide + 1);
    } else if (action === 'prev' && !onFirstSlide) {
      carouselRef.current?.goToPreviousItem();
      setCurrentSlide(currentSlide - 1);
    }
  };

  return (
    <div className={classNames(styles.Slider, styles[theme], className)}>
      {(heading ?? description ?? cta) && (
        <TextComponent
          heading={heading ?? ''}
          headingSize={headingSize}
          copy={description}
          theme={theme}
          {...(cta && {
            ctas: {
              primary: {
                copy: cta?.label ?? '',
                href: cta?.url ?? '',
              },
            },
          })}
        />
      )}
      <KatCarousel className={styles.SliderCarousel} ref={carouselRef} disableNavDots disableNavArrows>
        <KatCarouselItems className={classNames(styles.SliderCarouselItems, displayContents && styles.displayContents)}>
          {chunkedChildren.map((slide) => (
            <KatCarouselItem key={slide[0].key} className={styles.SliderCarouselItem}>
              <div className="kat-row">
                {slide.map((child) => (
                  <section
                    key={child.key}
                    className={classNames(styles.cardContainer, styles[size], `kat-col-xs-${elementGridSize}`)}
                  >
                    {child}
                  </section>
                ))}
              </div>
            </KatCarouselItem>
          ))}
        </KatCarouselItems>
      </KatCarousel>
      <div className={styles.SliderActions}>
        {slideCount > 1 && (
          <>
            {isLargeDevice && (
              <div className={styles.SliderProgress}>
                <KatProgress
                  end-value={totalElements}
                  className={styles[theme]}
                  hide-value
                  size="small"
                  start-value={0}
                  state="working"
                  type="linear"
                  value={currentSlideElements}
                />
              </div>
            )}
            <span className={classNames(styles.SliderPagination, styles[theme])}>
              {currentSlideElements} {t(commonBundle, SLIDER_PAGINATION_LABEL)} {totalElements}
            </span>
            <div className={styles.Navigation}>
              <PrimaryButton
                data-testid="slider-prev-button"
                variant="outlined"
                disabled={onFirstSlide}
                name="prev"
                buttonSize={PRIMARY_BUTTON_SIZE.Small}
                icon="chevronLeft"
                onClick={handleTransition}
                theme={theme}
              />
              <PrimaryButton
                data-testid="slider-next-button"
                variant="outlined"
                disabled={onLastSlide}
                name="next"
                buttonSize={PRIMARY_BUTTON_SIZE.Small}
                icon="chevronRight"
                onClick={handleTransition}
                theme={theme}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
