import { ResourceType } from '@amzn/coral_com-amazon-sssapinterface-model';
import { useBundle } from '@amzn/react-arb-tools';

import { COMMON_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers';

/**
 * A hook for sharing the the resource bundle for "common" strings shared across the website.
 * @returns {commonBundle} resource bundle and helper methods that utilize the strings.
 */
export const useCommonStrings = () => {
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  const getCtaByResource = (type: ResourceType) => {
    switch (type) {
      case ResourceType.PLAYBOOK:
        return { icon: 'download', label: t(commonBundle, 'downloadPlaybook_label') ?? '' };
      case ResourceType.POLICY:
        return { icon: 'chevronRight', label: t(commonBundle, 'visitTheSite_label') ?? '' };
      case ResourceType.CASE_STUDY:
        return { icon: 'chevronRight', label: t(commonBundle, 'readCaseStudy_label') ?? '' };
      case ResourceType.COLLABORATORS:
        return { icon: 'chevronRight', label: t(commonBundle, 'visitTheSite_label') ?? '' };
      case ResourceType.DATA:
        return { icon: 'chevronRight', label: t(commonBundle, 'visitTheSite_label') ?? '' };
      case ResourceType.GUIDANCE:
        return { icon: 'chevronRight', label: t(commonBundle, 'visitTheSite_label') ?? '' };
      case ResourceType.TOOL:
        return { icon: 'chevronRight', label: t(commonBundle, 'visitTheSite_label') ?? '' };
      case ResourceType.METHODOLOGY:
        return { icon: 'chevronRight', label: t(commonBundle, 'visitTheSite_label') ?? '' };
      default:
        return { icon: 'chevronRight', label: t(commonBundle, 'visitSite_label') ?? '' };
    }
  };

  return {
    commonBundle,
    getCtaByResource,
  };
};
