import { FunctionComponent, RefObject } from 'react';
import styled from 'styled-components';

import { MessageBundle } from '@amzn/arb-tools';
import { SearchResult as SearchResultModel } from '@amzn/coral_com-amazon-sssapinterface-model-structures-search';
import { KatButton, KatIcon } from '@amzn/katal-react';
import variables from '@amzn/sss-website-theme/dist/theme/variables.module.scss';

import { PRIMARY_BUTTON_SIZE, PrimaryButton } from 'src/components/atoms/primary-button/PrimaryButton';
import { INPUT_STYLE, SearchInput } from 'src/components/pages/search/SearchInput';
import {
  CLEAR_BUTTON_LABEL,
  MAX_SEARCH_QUERY_LENGTH,
  SEARCH_PLACEHOLDER_LABEL,
  SEARCH_TITLE_LABEL,
  SUBMIT_BUTTON_LABEL,
  COLLAPSED_SEARCH_PLACEHOLDER_LABEL,
} from 'src/constants';
import { t } from 'src/helpers/translation-helpers';
import useDeviceSize from 'src/hooks/useDeviceSize.hook';

import styles from './SearchBar.module.scss';

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const ClearButton = styled(KatButton)`
  --border-width: 0;
  --background: transparent;
  --border-color: transparent;
  --border-radius: 0;
  --border-style: solid;
  --box-shadow: none;
  --color: ${variables.greenPrimary};
  --line-height: 1.4;
  --padding: 0;
  --background-hover: transparent;
  --border-color-hover: transparent;
  --color-hover: ${variables.greenPrimary};
  --color-focus: ${variables.greenPrimary};
  --background-focus: transparent;
  --hover-ring-color: transparent;
  --background-active: transparent;
  --background-disabled: transparent;
  --border-color-active: transparent;
  --hover-ring-radius: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${variables.greenPrimary};
  }
`;

export interface SearchBarProps {
  query: string;
  searchBundle: MessageBundle;
  inputRef: RefObject<HTMLInputElement>;
  handleSubmit: (event: React.FormEvent) => void;
  handleSearchInput: (event: any) => void;
  handleClearClick: () => void;
  searchParams: URLSearchParams;
  searchResults: SearchResultModel[] | undefined;
  hasError: boolean;
}

export const SearchBar: FunctionComponent<SearchBarProps> = ({
  query,
  searchBundle,
  inputRef,
  handleSubmit,
  handleSearchInput,
  handleClearClick,
  searchParams,
  searchResults,
  hasError,
}) => {
  const { isSmallDesktop } = useDeviceSize();

  // For mobile, if there are search results the search bar collapses to provide more space
  return searchResults && searchResults?.length > 0 && !isSmallDesktop ? (
    <form data-testid="search-form" onSubmit={handleSubmit}>
      <div className={styles.collapsedContainer}>
        <SearchInput
          placeholder={t(searchBundle, COLLAPSED_SEARCH_PLACEHOLDER_LABEL)}
          inputRef={inputRef}
          value={query}
          maxLength={MAX_SEARCH_QUERY_LENGTH}
          onInput={handleSearchInput}
          className={INPUT_STYLE.Collapsed}
        />
        <div className={styles.collapsedClearButtonContainer} data-testid="clear-button">
          {query.trim() && (
            <button type="button" className={styles.collapsedClearButton} onClick={handleClearClick}>
              <KatIcon className={styles.clearIcon} size="tiny" name="close" />
            </button>
          )}
        </div>
      </div>
      {document.activeElement === inputRef.current && (
        <div className={styles.counterContainer}>
          <span className={styles.counter}>
            {query.length}/{MAX_SEARCH_QUERY_LENGTH}
          </span>
        </div>
      )}
    </form>
  ) : (
    <div className={searchResults ? styles.searchBarContainerCollapsed : styles.searchBarContainer}>
      <h2 className={styles.title}>{t(searchBundle, SEARCH_TITLE_LABEL)}</h2>
      <form data-testid="search-form" onSubmit={handleSubmit}>
        <div className={styles.inputContainer}>
          <SearchInput
            placeholder={t(searchBundle, SEARCH_PLACEHOLDER_LABEL)}
            inputRef={inputRef}
            value={query}
            maxLength={MAX_SEARCH_QUERY_LENGTH}
            onInput={handleSearchInput}
            className={INPUT_STYLE.Regular}
          />
          {document.activeElement === inputRef.current && (
            <span className={styles.counter}>
              {query.length}/{MAX_SEARCH_QUERY_LENGTH}
            </span>
          )}
        </div>
        <ButtonContainer>
          <div data-testid="submit-button" role="button">
            <PrimaryButton
              disabled={hasError ? false : !query.trim() || query === searchParams.get('q')}
              label={t(searchBundle, SUBMIT_BUTTON_LABEL)}
              icon="chevronRight"
              type="submit"
              buttonSize={PRIMARY_BUTTON_SIZE.Small}
            />
          </div>
          <div data-testid="clear-button" role="button">
            <ClearButton
              disabled={!query.trim()}
              label={t(searchBundle, CLEAR_BUTTON_LABEL)}
              onClick={handleClearClick}
            />
          </div>
        </ButtonContainer>
      </form>
    </div>
  );
};
