import classNames from 'classnames';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import styles from './AsxMarkdownContent.module.scss';

interface AsxMarkdownContentProps {
  copy: string;
  className?: string;
}

export const AsxMarkdownContent: React.FC<AsxMarkdownContentProps> = ({ copy, className }) => (
  <ReactMarkdown className={classNames(styles['markdown-copy'], className)}>{copy}</ReactMarkdown>
);
