// ASX Image Metrics
export const IMAGE_LOAD_TIME = 'image-load-time';
export const IMAGE_LOAD_SUCCESS = 'image-load-success';
export const IMAGE_LOAD_FAILURE = 'image-load-failure';

// Translation metrics
export const TRANSLATION_STRING_FAILURE = 'translation-string-failure';
export const STRING_ID_DIMENSION = 'stringId';

// Error Boundary metrics
export const ERROR_BOUNDARY_ENCOUNTER = 'error-boundary-encounter';
export const INIT_ERROR_BOUNDARY_ENCOUNTER = 'AppInitErrorBoundary.Encounter';

// Search metrics
export const SEARCH_ERROR = 'search-error';
export const PROCESS_DIMENSION = 'process';
export const RESULT_ID_DIMENSION = 'resultId';

// Referer metrics
export const REFERER_ENCOUNTER = 'referer-encounter';
export const ORIGIN_DIMENSION = 'origin';
