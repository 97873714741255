import { CLOUDFRONT_PREFIX } from 'src/constants';

// Converts a string to sentence case (i.e where just the first letter of the string is capitalized
export const convertToSentenceCase = (str: string) =>
  str.toLowerCase().replace(/^\w/, (char) => char.toLocaleUpperCase());

export const formatUrl = (url?: string) => {
  if (!url) return '';
  // if the string is already a valid url, simply return
  return url.startsWith('http') || url.startsWith('www') ? url : `${CLOUDFRONT_PREFIX}${url}`;
};
