import classNames from 'classnames';
import { FunctionComponent, HTMLAttributes, PropsWithChildren } from 'react';

import styles from './PageSection.module.scss';

interface PageSectionProps extends PropsWithChildren, HTMLAttributes<HTMLDivElement> {
  className?: string;
  theme?: 'grey-secondary' | 'green-primary' | 'slate-dark' | 'grey-tertiary';
}

export const PageSection: FunctionComponent<PageSectionProps> = ({ className, children, theme, ...props }) => {
  const classes = classNames(
    styles.pageSection,
    theme === 'green-primary' && styles.greenPrimary,
    theme === 'slate-dark' && styles.slateDark,
    theme === 'grey-tertiary' && styles.greyTertiary,
    className
  );

  return (
    <section className={classes} {...(props as HTMLAttributes<HTMLDivElement>)}>
      <div className={styles.boundedContainer}>{children}</div>
    </section>
  );
};
