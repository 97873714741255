import classNames from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { PRIMARY_BUTTON_SIZE, PrimaryButton } from 'src/components/atoms/primary-button/PrimaryButton';
import { SEARCH_RESULTS_PAGE_BUNDLE_NAME, SCROLL_TO_TOP_LABEL } from 'src/constants';
import { t } from 'src/helpers/translation-helpers';

import styles from './ScrollToTopButton.module.scss';

// TODO add tests for this component
// https://app.asana.com/0/1206972973144714/1207566452003710/f
export const ScrollToTopButton: FunctionComponent = () => {
  const [visible, setVisible] = useState(false);
  const [searchBundle] = useBundle(SEARCH_RESULTS_PAGE_BUNDLE_NAME);

  const toggleVisible = () => {
    // If visibility should be triggered higher or lower
    // multiply innerHeight by a normalized scalar
    const scrolled = window.scrollY;
    if (scrolled > window.innerHeight) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 10,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);

    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  return (
    <div className={styles.scrollButtonContainer}>
      <PrimaryButton
        className={classNames(styles.scrollButton, visible && styles.visible)}
        label={t(searchBundle, SCROLL_TO_TOP_LABEL)}
        buttonSize={PRIMARY_BUTTON_SIZE.Small}
        icon="arrowUp"
        onClick={scrollToTop}
      />
    </div>
  );
};
