import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';
import { createMetricsPublisher } from '@amzn/katal-metrics-mons';

// eslint-disable-next-line no-console
const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
  // Note that if we are in production or SERVER_MODE 'mons' we auto-configure before running this function.
  // So for Mons apps these configurations are just for unit tests and local server tests.
  if (process.env.NODE_ENV === 'test') {
    const metricsDriver = new KatalMetricsDriverArrayCollector();
    //  Attach to global window object so tests can see it
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).metricsDriver = metricsDriver;
    return metricsDriver;
  }
  if (process.env.NODE_ENV !== 'production') {
    return new KatalMetricsDriverConsoleLogJson();
  }
  return new KatalMetricsDriverSushi.Builder()
    .withDomainRealm('test', 'USAmazon')
    .withErrorHandler(metricsConsoleErrorHandler)
    .build();
};

// Creating a separate metrics publisher with 'prod' domain to publish pageView metrics.
const makeProdPublisher = (): KatalMetrics.Publisher => {
  const metricsDriver = makeMetricsDriver();
  const initialMetricsContext = new KatalMetrics.Context.Builder()
    .withSite('ashSustainabilityWebsite')
    .withServiceName('SustainabilitySolutionsHub')
    .build();
  return new KatalMetrics.Publisher(metricsDriver, metricsConsoleErrorHandler, initialMetricsContext);
};

const makePublisher = (): KatalMetrics.Publisher => {
  if (!SERVER_MODE || SERVER_MODE === 'mons') {
    return createMetricsPublisher();
  }
  // Otherwise we are running a test server outside of Mons so cannot use KatalMetricsMons to auto-configure.
  return makeProdPublisher();
};

const initialMetricsPublisher = makePublisher();

export default initialMetricsPublisher;
