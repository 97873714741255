import classNames from 'classnames';
import { useCallback, useState } from 'react';

import { Dropdown, DropdownOption, FilterTag } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import useDeviceSize from 'src/hooks/useDeviceSize.hook';

import styles from './AccordionsComponent.module.scss';
import {
  AccordionsComponentContent,
  type AccordionsComponentContentProps,
} from './accordions-component-content/AccordionsComponentContent';

export type AccordionsComponentProps = {
  theme?: SECTION_THEME;
  variant?: string;
  content: Array<AccordionsComponentContentProps>;
};

export const AccordionsComponent: React.FC<AccordionsComponentProps> = ({
  content,
  theme = SECTION_THEME.Light,
  variant = 'full',
}) => {
  const [showContentIndex, setShowContentIndex] = useState<number>(0);
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number>(0);
  const { isLargeDevice } = useDeviceSize();

  const handleFilterTagClick = useCallback((index: number) => {
    setShowContentIndex(index);
    setOpenAccordionIndex(0); // Reset the accordion to the first one when the filter tag changes
  }, []);

  const handleAccordionClick = useCallback((index: number) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? -1 : index));
  }, []);

  const handleDropdownChange = useCallback(
    (event: any) => {
      // Explicitly cast event to a custom structure
      const e = event as { target: { value: string } };
      const selectedValue = e.target.value;
      const selectedIndex = content.findIndex((item) => item.cardTitle === selectedValue);
      setShowContentIndex(selectedIndex);
      setOpenAccordionIndex(0); // Reset the accordion to the first one when the filter tag changes
    },
    [content]
  );

  const dropdownOptions: DropdownOption[] = content.map((item) => ({
    text: item.cardTitle,
    icon: item.icon,
  }));

  return (
    <div className={styles.AccordionsComponent} data-testid="accordions-component">
      <div className={styles.contentWrapper}>
        {content.length > 1 &&
          (isLargeDevice ? (
            <div className={styles.filterTags}>
              {content.map((item, index) => (
                <FilterTag
                  theme={theme}
                  key={item.cardTitle}
                  onClick={() => handleFilterTagClick(index)}
                  icon={item.icon}
                  label={item.cardTitle}
                  isActive={index === showContentIndex}
                  data-testid="filter-tag"
                />
              ))}
            </div>
          ) : (
            <div className={classNames(styles.dropdownWrapper, styles[theme])}>
              <Dropdown
                className={styles.dropdown}
                options={dropdownOptions}
                value={dropdownOptions[showContentIndex].text}
                onChange={handleDropdownChange}
                data-testid="filter-dropdown"
              />
            </div>
          ))}
        <div className={styles.accordions}>
          <AccordionsComponentContent
            theme={theme}
            {...(variant === 'reduced' && { showCardHeader: false })}
            cardTitle={content[showContentIndex]?.cardTitle}
            cardSubtitle={content[showContentIndex]?.cardSubtitle}
            cardImage={content[showContentIndex]?.cardImage}
            accordions={content[showContentIndex]?.accordions.map((accordion, index) => ({
              ...accordion,
              isOpen: index === openAccordionIndex,
              onClick: () => handleAccordionClick(index),
            }))}
            icon={content[showContentIndex]?.icon}
            data-testid="accordions-component-content"
          />
        </div>
      </div>
    </div>
  );
};
