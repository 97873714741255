import { ResourceType } from '@amzn/coral_com-amazon-sssapinterface-model';

export const DEFAULT_LOCALE = 'en-US';
export const MAX_SEARCH_QUERY_LENGTH = 100;

// Search Labels
export const MAIN_SEARCH_RESULTS_LABEL = 'mainSearchResults_label';
export const MAIN_SEARCH_RESULTS_LABEL_SINGULAR = 'mainSearchResultsSingular_label';
export const NO_SEARCH_RESULTS_LABEL = 'noSearchResults_label';
export const SEARCH_ERROR_LABEL = 'searchError_label';
export const SEARCH_TITLE_LABEL = 'searchTitle_label';
export const CLEAR_BUTTON_LABEL = 'searchClearButton_label';
export const SUBMIT_BUTTON_LABEL = 'searchButton_label';
export const SEARCH_PLACEHOLDER_LABEL = 'searchPlaceholder_label';
export const NO_SEARCH_RESULTS_ACTION_LABEL = 'noSearchResultsAction_label';
export const GETTING_STARTED_LINK_LABEL = 'gettingStartedLink_label';
export const SCROLL_TO_TOP_LABEL = 'scrollToTop_label';
export const COLLAPSED_SEARCH_PLACEHOLDER_LABEL = 'collapsedSearchPlaceholder_label';
export const BACK_BUTTON_LABEL = 'backButton_label';

// Panther Resource Bundles
export const COMMON_BUNDLE_NAME = 'common';
export const HOMEPAGE_MESSAGE_BUNDLE_NAME = 'pages.homepage';
export const ASSET_GRAPHIC_MESSAGE_BUNDLE_NAME = 'assetgraphic';
export const FOCUS_AREA_PAGES_BUNDLE_NAME = 'pages.focusarea';
export const SEARCH_RESULTS_PAGE_BUNDLE_NAME = 'pages.searchresultspage';
export const GETTING_STARTED_PAGE_BUNDLE_NAME = 'pages.gettingstartedpage';
export const SCIENCE_EXCHANGE_PAGE_BUNDLE_NAME = 'pages.scienceexchange';
export const MEASUREMENT_PAGE_BUNDLE_NAME = 'pages.measurement';
export const NOT_FOUND_RESOURCE_BUNDLE_NAME = 'templates.notfound';
export const ERROR_WRAPPER_RESOURCE_BUNDLE_NAME = 'templates.error';
export const DATA_INITIATIVE_PAGE_BUNDLE_NAME = 'pages.datainitiativepage';

export const SLIDER_PAGINATION_LABEL = 'sliderPagination_label';

// NOTE - if a new bundle is added for a ResourceType, please add to Map below
export const POLICY_RESOURCE_BUNDLE_NAME = 'resources.policy';
export const PLAYBOOK_RESOURCE_BUNDLE_NAME = 'resources.playbook';
export const SECONDARY_HERO_RESOURCE_BUNDLE_NAME = 'resources.secondaryhero';
export const CASE_STUDY_RESOURCE_BUNDLE_NAME = 'resources.casestudy';
export const METHODOLOGY_RESOURCE_BUNDLE_NAME = 'resources.methodology';
export const GUIDANCE_RESOURCE_BUNDLE_NAME = 'resources.guidance';
export const COLLABORATORS_RESOURCE_BUNDLE_NAME = 'resources.collaborators';
export const TOOL_RESOURCE_BUNDLE_NAME = 'resources.tool';
export const DATA_RESOURCE_BUNDLE_NAME = 'resources.data';
export const RESOURCE_TYPE_TO_BUNDLE_MAP: { [K in ResourceType]?: string } = {
  [ResourceType.PLAYBOOK]: PLAYBOOK_RESOURCE_BUNDLE_NAME,
  [ResourceType.POLICY]: POLICY_RESOURCE_BUNDLE_NAME,
  [ResourceType.SECONDARY_HERO]: SECONDARY_HERO_RESOURCE_BUNDLE_NAME,
  [ResourceType.CASE_STUDY]: CASE_STUDY_RESOURCE_BUNDLE_NAME,
  [ResourceType.METHODOLOGY]: METHODOLOGY_RESOURCE_BUNDLE_NAME,
  [ResourceType.GUIDANCE]: GUIDANCE_RESOURCE_BUNDLE_NAME,
  [ResourceType.COLLABORATORS]: COLLABORATORS_RESOURCE_BUNDLE_NAME,
  [ResourceType.TOOL]: TOOL_RESOURCE_BUNDLE_NAME,
  [ResourceType.DATA]: DATA_RESOURCE_BUNDLE_NAME,
};

export const CLOUDFRONT_PREFIX = `https://dn838xj7iw6s8.cloudfront.net`;
