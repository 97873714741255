import { FunctionComponent, ChangeEvent, RefObject, useEffect, useState } from 'react';

import useDeviceSize from 'src/hooks/useDeviceSize.hook';

import styles from './SearchInput.module.scss';

export enum INPUT_STYLE {
  Regular = 'searchInput',
  Collapsed = 'collapsedSearchInput',
}

const MIN_FONT_SIZE_MOBILE = 20;
const MIN_FONT_SIZE_DESKTOP = 35;
const MAX_FONT_SIZE_MOBILE = 35;
const MAX_FONT_SIZE_DESKTOP = 80;
const MAX_CHAR_COUNT_BEFORE_TEXTWRAP_DESKTOP = 60;
const MAX_CHAR_COUNT_BEFORE_TEXTWRAP_MOBILE = 35;

interface SearchInputProps {
  placeholder?: string;
  value: string;
  maxLength: number;
  onInput: (e: ChangeEvent<HTMLInputElement>) => void;
  inputRef: RefObject<HTMLInputElement>;
  className: INPUT_STYLE;
}

// TODO add unit tests
export const SearchInput: FunctionComponent<SearchInputProps> = ({
  placeholder,
  value,
  maxLength,
  onInput,
  inputRef,
  className,
}) => {
  const { isSmallDesktop } = useDeviceSize();
  const [fontSize, setFontSize] = useState(isSmallDesktop ? MAX_FONT_SIZE_DESKTOP : MAX_FONT_SIZE_MOBILE);

  useEffect(() => {
    const maxFontSize = isSmallDesktop ? MAX_FONT_SIZE_DESKTOP : MAX_FONT_SIZE_MOBILE;
    const minFontSize = isSmallDesktop ? MIN_FONT_SIZE_DESKTOP : MIN_FONT_SIZE_MOBILE;
    const maxCharCountBeforeTextWrap = isSmallDesktop
      ? MAX_CHAR_COUNT_BEFORE_TEXTWRAP_DESKTOP
      : MAX_CHAR_COUNT_BEFORE_TEXTWRAP_MOBILE;
    const scalingFactor = (maxFontSize - minFontSize) / maxCharCountBeforeTextWrap;
    const newFontSize = Math.max(minFontSize, maxFontSize - scalingFactor * value.length);
    setFontSize(newFontSize);
  }, [isSmallDesktop, value]);

  return (
    <input
      data-testid="search-input"
      type="search"
      className={styles[className]}
      placeholder={placeholder}
      value={value}
      maxLength={maxLength}
      onInput={onInput}
      ref={inputRef}
      style={{ fontSize: `${fontSize}px` }}
    />
  );
};
