import classNames from 'classnames';
import { type ReactElement } from 'react';

import { Icon } from 'src/components/atoms';
import { IconType } from 'src/components/atoms/icons/icons';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './FilterTag.module.scss';

interface FilterTagProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: SECTION_THEME;
  className?: string;
  icon: IconType;
  label: string;
  isActive?: boolean;
}

export function FilterTag({
  icon,
  label,
  isActive,
  className,
  theme = SECTION_THEME.Dark,
  ...props
}: FilterTagProps): ReactElement {
  return (
    <button
      type="button"
      className={classNames(styles.FilterTag, styles[theme], isActive && styles.isActive, className)}
      {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      <Icon className={styles.icon} name={icon} />
      <p className={styles.label}>{label}</p>
    </button>
  );
}
