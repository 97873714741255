import { FunctionComponent } from 'react';

import { KatModal } from '@amzn/katal-react';

import useDeviceSize from 'src/hooks/useDeviceSize.hook';

import styles from './Modal.module.scss';

interface ModalList {
  heading?: string;
  elements: string[];
}

export interface ModalProps {
  isVisible: boolean;
  close: () => void;
  title?: string;
  subtitle?: string;
  list?: ModalList;
}

export const Modal: FunctionComponent<ModalProps> = ({ isVisible, close, title, subtitle, list }) => {
  const modal = document.querySelector('kat-modal');
  const modalShadowRoot = modal?.shadowRoot;
  const { isMediumDevice } = useDeviceSize();

  if (modalShadowRoot) {
    const modalContainer: HTMLDivElement | null = modalShadowRoot.querySelector('.container');
    if (modalContainer) {
      modalContainer.style.alignItems = 'flex-start';
      if (isMediumDevice) {
        // In design there's a 8px padding on the left side but only when above small device resolutions;
        modalContainer.style.paddingLeft = '8px';
      }
    }
  }

  return (
    <KatModal className={styles.Modal} visible={isVisible} onClose={close}>
      <span slot="title" />
      <h3 className={styles.Title}>{title}</h3>
      <p className={styles.Subtitle}>{subtitle}</p>

      <div className={styles.ModalList}>
        <h4 className={styles.ModalListHeading}>{list?.heading}</h4>
        {list &&
          list.elements.map((element, index) => (
            <div key={element} className={styles.ModalListElement}>
              <span className={styles.ListElementIndex}>[0{index + 1}]</span>
              <span className={styles.ListElementString}>{element}</span>
            </div>
          ))}
      </div>
    </KatModal>
  );
};
