import alternativeFuels from 'src/components/atoms/icon/svg/alternative-fuels.svg';
import arrowDown from 'src/components/atoms/icon/svg/arrow-down.svg';
import arrowUp from 'src/components/atoms/icon/svg/arrow-up.svg';
import building from 'src/components/atoms/icon/svg/building.svg';
import check from 'src/components/atoms/icon/svg/check.svg';
import chevronDown from 'src/components/atoms/icon/svg/chevron-down.svg';
import chevronLeft from 'src/components/atoms/icon/svg/chevron-left.svg';
import chevronRight from 'src/components/atoms/icon/svg/chevron-right.svg';
import chevronTopRight from 'src/components/atoms/icon/svg/chevron-top-right.svg';
import close from 'src/components/atoms/icon/svg/close.svg';
import dashedBorder from 'src/components/atoms/icon/svg/dashed-border.svg';
import download from 'src/components/atoms/icon/svg/download.svg';
import eye from 'src/components/atoms/icon/svg/eye.svg';
import info from 'src/components/atoms/icon/svg/info.svg';
import library from 'src/components/atoms/icon/svg/library.svg';
import logo from 'src/components/atoms/icon/svg/logo.svg';
import menu from 'src/components/atoms/icon/svg/menu.svg';
import minus from 'src/components/atoms/icon/svg/minus.svg';
import packaging from 'src/components/atoms/icon/svg/packaging.svg';
import paper from 'src/components/atoms/icon/svg/paper.svg';
import plus from 'src/components/atoms/icon/svg/plus.svg';
import progressDots from 'src/components/atoms/icon/svg/progress-dots.svg';
import screen from 'src/components/atoms/icon/svg/screen.svg';
import section01 from 'src/components/atoms/icon/svg/section-01.svg';
import tcp from 'src/components/atoms/icon/svg/tcp.svg';
import triangleDots from 'src/components/atoms/icon/svg/triangle-dots.svg';
import wall from 'src/components/atoms/icon/svg/wall.svg';

export const icons = {
  alternativeFuels,
  arrowDown,
  arrowUp,
  building,
  check,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronTopRight,
  close,
  dashedBorder,
  download,
  eye,
  info,
  library,
  logo,
  packaging,
  progressDots,
  screen,
  section01,
  tcp,
  triangleDots,
  wall,
  menu,
  paper,
  minus,
  plus,
};

export type IconType = keyof typeof icons;
