import { useMediaQuery } from 'react-responsive';

import variables from '@amzn/sss-website-theme/dist/theme/variables.module.scss';

const useDeviceSize = () => {
  const isXSmallDevice = useMediaQuery({ query: `(max-width: ${variables.xSmallDevice})` });
  const isSmallDevice = useMediaQuery({ query: `(min-width: ${variables.smallDevice})` });
  const isMediumDevice = useMediaQuery({ query: `(min-width: ${variables.mediumDevice})` });
  const isLargeDevice = useMediaQuery({ query: `(min-width: ${variables.largeDevice})` });
  const isSmallDesktop = useMediaQuery({ query: `(min-width: ${variables.smallDesktop})` });
  const isMediumDesktop = useMediaQuery({ query: `(min-width: ${variables.mediumDesktop})` });
  const isLargeDesktop = useMediaQuery({ query: `(min-width: ${variables.largeDesktop})` });

  return {
    isXSmallDevice,
    isSmallDevice,
    isMediumDevice,
    isLargeDevice,
    isSmallDesktop,
    isMediumDesktop,
    isLargeDesktop,
  };
};

export default useDeviceSize;
