import { FunctionComponent, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import {
  AccordionDisplay,
  Resource,
  SectionType,
  ResourceType,
  Page,
} from '@amzn/coral_com-amazon-sssapinterface-model';
import { KatProgress } from '@amzn/katal-react';
import { useBundle } from '@amzn/react-arb-tools';

import { IconType } from 'src/components/atoms';
import {
  AnchorLinks,
  Card,
  ListComponent,
  MainBanner,
  PageSection,
  SecondaryHero,
  Slider,
  TeaserCard,
  TeaserCardImage,
  TextComponent,
} from 'src/components/common';
import { useFocusAreaTeaser } from 'src/components/pages/home/hooks/useFocusAreaCards';
import { ClimatePledgeSection } from 'src/components/templates/ClimatePledgeSection';
import { ROUTES } from 'src/data/enums/Routes';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';
import { formatResourceTypeForPill } from 'src/helpers/resource-helpers';
import { useCommonStrings } from 'src/hooks/useCommonStrings';
import fallbackListComponentImage from 'src/images/resources/listComponentImage.jpeg';
import measurementImage from 'src/images/resources/measureAndReportBannerImage.jpg';
import scienceExchangeImage from 'src/images/resources/scienceExchangeBannerImage.jpg';

import { useResourcePageSections } from './useResourcePageSections';

interface ResourceTemplateProps extends PropsWithChildren {
  page: Page;
  mainBundle: ReturnType<typeof useBundle>[0];
}

export const ResourceTemplate: FunctionComponent<ResourceTemplateProps> = ({ children, page, mainBundle }) => {
  const { getCtaByResource } = useCommonStrings();

  const {
    isBundleLoading,
    commonBundle,
    secondaryHeroSection,
    secondaryHeroResourceBundle,
    secondaryHeroResource,
    secondaryHeroImageAlt,
    secondaryHeroImage,
    playbookSection,
    playbookResource,
    playBookResourceBundle,
    playbookPdf,
    playbookImage,
    playbookImageAlt,
    playbookAccordions,
    caseStudiesSection,
    caseStudiesResources,
    caseStudiesResourceBundle,
    additionalResSection,
    additionalResResources,
    bundleByResourceType,
    formatUrl,
  } = useResourcePageSections(page);

  const focusAreaTeasers = useFocusAreaTeaser();
  const location = useLocation();

  if (!page) {
    return <Navigate to="/404" />;
  }

  if (isBundleLoading || !mainBundle) {
    return <KatProgress indeterminate type="circular" />;
  }

  const isMeasurementPage = location.pathname === ROUTES.MEASUREMENT;

  return (
    <>
      {secondaryHeroSection && (
        <PageSection id={SectionType.SECONDARY_HERO}>
          <SecondaryHero
            heading={t(secondaryHeroResourceBundle, secondaryHeroResource.display?.title)}
            copy={t(secondaryHeroResourceBundle, secondaryHeroResource.display?.shortDescription)}
            image={{
              alt: secondaryHeroImageAlt,
              src: secondaryHeroImage,
            }}
            theme={SECTION_THEME.Light}
          />
        </PageSection>
      )}

      <AnchorLinks key={page.id} />

      {playbookSection && (
        <PageSection theme="green-primary" data-anchor id={t(commonBundle, 'playbookAnchor_id')}>
          <MainBanner
            title={t(playBookResourceBundle, playbookResource.display?.title)}
            accordions={
              playbookAccordions?.map((accordion: AccordionDisplay) => ({
                title: t(playBookResourceBundle, accordion.title),
                content: [{ text: t(playBookResourceBundle, accordion.description) }],
              })) ?? []
            }
            imageUrl={playbookImage}
            alt={playbookImageAlt}
            cta={{
              icon: getCtaByResource(playbookResource.resourceType as ResourceType).icon as IconType,
              href: playbookPdf,
              label: getCtaByResource(playbookResource.resourceType as ResourceType).label,
            }}
            theme={SECTION_THEME.Dark}
          />
        </PageSection>
      )}

      {caseStudiesSection && (
        <PageSection theme="slate-dark" data-anchor id={t(commonBundle, 'caseStudiesAnchor_id')}>
          <Slider
            key={`case-studies-slider-${page.id}`}
            displayContents
            size="large"
            theme={SECTION_THEME.Dark}
            heading={t(mainBundle, caseStudiesSection.display?.title)}
            description={t(mainBundle, caseStudiesSection.display?.shortDescription)}
          >
            {caseStudiesResources.map((resource: Resource) => (
              <Card
                headingSize="h4"
                theme={SECTION_THEME.Dark}
                pill={formatResourceTypeForPill(resource?.resourceType)}
                key={resource.display?.name}
                image={formatUrl(resource.assets.secondaryAssets?.thumbnail?.[0].assetUrl)}
                alt={resource.assets.secondaryAssets?.thumbnail?.[0].altText}
                title={t(caseStudiesResourceBundle, resource.display?.title)}
                subtitle={t(caseStudiesResourceBundle, resource.display?.shortDescription)}
                icon="chevronRight"
                link={{
                  label: getCtaByResource(resource.resourceType as ResourceType).label,
                  url: formatUrl(resource.assets.primaryAsset[0].assetUrl),
                }}
              />
            ))}
          </Slider>
        </PageSection>
      )}

      {children}

      {additionalResSection && (
        <PageSection data-anchor id={t(commonBundle, 'additionalResourcesAnchor_id')}>
          <TextComponent
            theme={SECTION_THEME.Light}
            heading={t(mainBundle, additionalResSection.display?.title) ?? ''}
            copy={t(mainBundle, additionalResSection.display?.shortDescription) ?? ''}
          />
          <ListComponent
            items={additionalResResources.map((resource: Resource) => ({
              title: t(bundleByResourceType[resource.resourceType], resource.display?.title) || '',
              subtitle: t(bundleByResourceType[resource.resourceType], resource.display?.shortDescription) || '',
              cta: {
                href: formatUrl(resource.assets.primaryAsset[0].assetUrl),
                icon:
                  resource.assets.primaryAsset[0].contentFormat === 'PDF'
                    ? ('download' as IconType)
                    : ('externalLink' as IconType),
              },
              image: {
                url: formatUrl(resource.assets.secondaryAssets?.thumbnail?.[0].assetUrl) || fallbackListComponentImage,
                alt: resource.assets.primaryAsset[0].altText ?? '',
              },
              ...(resource.assets.primaryAsset[0].contentFormat === 'PDF' && { label: 'PDF 2.1 MB' }),
            }))}
          />
        </PageSection>
      )}

      {!isMeasurementPage && (
        <PageSection theme="slate-dark">
          <TeaserCard title={commonBundle ? t(commonBundle, 'keepExploringLabel') ?? '' : ''}>
            {focusAreaTeasers && focusAreaTeasers.length > 1 ? (
              focusAreaTeasers.map((props) => <TeaserCardImage key={props.cardTitle} {...props} />)
            ) : (
              <>
                {/* fall back cards */}
                <TeaserCardImage
                  image={measurementImage}
                  cardTitle={commonBundle ? t(commonBundle, 'measurementTitle_label') ?? '' : ''}
                  cta={commonBundle ? t(commonBundle, 'learnMore_label') ?? '' : ''}
                  linkUrl={ROUTES.MEASUREMENT}
                  alt={commonBundle ? t(commonBundle, 'measurement_imageAlt') ?? '' : ''}
                />
                )
                <TeaserCardImage
                  image={scienceExchangeImage}
                  cardTitle={commonBundle ? t(commonBundle, 'scienceTitle_label') ?? '' : ''}
                  cta={commonBundle ? t(commonBundle, 'learnMore_label') ?? '' : ''}
                  linkUrl={ROUTES.SCIENCE_EXCHANGE}
                  alt={commonBundle ? t(commonBundle, 'science_imageAlt') ?? '' : ''}
                />
              </>
            )}
          </TeaserCard>
        </PageSection>
      )}
      <ClimatePledgeSection />
    </>
  );
};
