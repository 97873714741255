import { FunctionComponent } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { PRIMARY_BUTTON_SIZE, PrimaryButton } from 'src/components/atoms/primary-button/PrimaryButton';
import { formatResourceTypeForPill } from 'src/helpers/resource-helpers';
import { t } from 'src/helpers/translation-helpers';
import useDeviceSize from 'src/hooks/useDeviceSize.hook';

import styles from './SearchResult.module.scss';

interface SearchResultProps {
  documentTitle: string;
  sourceURI?: string;
  resultType?: string;
  description: string;
  translationBundleLabel: string;
}

interface ResultBoxProps {
  tag?: string;
  title: string;
  content: string;
  link?: string;
  translationBundleLabel: string;
}

const ResultBox: FunctionComponent<ResultBoxProps> = ({ tag, title, content, link, translationBundleLabel }) => {
  const [translationBundle] = useBundle(translationBundleLabel);
  const { isSmallDesktop } = useDeviceSize();

  if (!content || !title) return null;

  return (
    translationBundle && (
      <div className={styles.resultBoxContainer}>
        <div className={styles.contentContainer}>
          {tag && <div className={styles.tagSlot}>{formatResourceTypeForPill(tag)}</div>}
          <h4 className={styles.title}>{title}</h4>
          <p className={styles.content}>{t(translationBundle, content)}</p>
        </div>
        <div className={styles.linkContainer}>
          {link && (
            <div role="link">
              <PrimaryButton
                target="_self"
                link={link}
                icon="chevronRight"
                buttonSize={isSmallDesktop ? PRIMARY_BUTTON_SIZE.Regular : PRIMARY_BUTTON_SIZE.Small}
              />
            </div>
          )}
        </div>
      </div>
    )
  );
};

export const SearchResult: FunctionComponent<SearchResultProps> = ({
  documentTitle,
  description,
  resultType,
  sourceURI,
  translationBundleLabel,
}) => (
  <ResultBox
    tag={resultType}
    title={documentTitle}
    content={description}
    link={sourceURI}
    translationBundleLabel={translationBundleLabel}
  />
);
