import classNames from 'classnames';
import { FunctionComponent, useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useBundle } from '@amzn/react-arb-tools';

import { PageSection } from 'src/components/common/page-section/PageSection';
import { BackButton } from 'src/components/pages/search/BackButton';
import { ScrollToTopButton } from 'src/components/pages/search/ScrollToTopButton';
import { SearchBar } from 'src/components/pages/search/SearchBar';
import { SearchResults } from 'src/components/pages/search/SearchResults';
import useSearch from 'src/components/pages/search/hooks/use-search';
import { SEARCH_RESULTS_PAGE_BUNDLE_NAME } from 'src/constants';

import styles from './SearchResultsPage.module.scss';

export const SearchResultsPage: FunctionComponent = () => {
  const [query, setQuery] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchBundle] = useBundle(SEARCH_RESULTS_PAGE_BUNDLE_NAME);
  const inputRef = useRef<HTMLInputElement>(null);

  const { searchResults, isLoading, hasError } = useSearch(searchParams);

  useEffect(() => {
    const queryString = searchParams.get('q') ?? '';
    setQuery(queryString.trim());
  }, [searchParams]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    inputRef.current?.blur();
    const newSearchParams = new URLSearchParams(window.location.search);
    newSearchParams.set('q', query);
    const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;
    if (searchParams.toString() === newSearchParams.toString()) {
      window.location.reload(); // force re-render on error
    } else {
      window.history.pushState({ searchParams: newSearchParams.toString() }, '', newUrl);
      setSearchParams(newSearchParams);
    }
  };

  const handleSearchInput = (event: any) => {
    setQuery(event.target.value);
  };

  const handleClearClick = () => {
    setQuery('');
    inputRef.current?.focus();
  };

  return (
    <>
      <PageSection
        className={classNames(styles.searchBarWrapper, searchResults && searchResults.length > 0 && styles.hasResults)}
        theme="grey-secondary"
      >
        <BackButton searchBundle={searchBundle} />
        <SearchBar
          query={query}
          searchBundle={searchBundle}
          inputRef={inputRef}
          handleSubmit={handleSubmit}
          handleSearchInput={handleSearchInput}
          handleClearClick={handleClearClick}
          searchParams={searchParams}
          searchResults={searchResults}
          hasError={hasError}
        />
      </PageSection>
      <PageSection theme="grey-secondary">
        <div className={styles.searchResultsContainer}>
          <SearchResults
            searchParams={searchParams}
            searchResults={searchResults}
            isLoading={isLoading}
            hasError={hasError}
            searchBundle={searchBundle}
          />
        </div>
      </PageSection>
      <ScrollToTopButton />
    </>
  );
};
