import classNames from 'classnames';
import { HTMLAttributes, ReactElement, forwardRef } from 'react';

import variables from '@amzn/sss-website-theme/dist/theme/variables.module.scss';

import styles from './GraphicCircle.module.scss';

interface GraphicCircleProps extends HTMLAttributes<SVGElement> {
  isBackground?: boolean;
}

const GraphicCircle = forwardRef<SVGSVGElement, GraphicCircleProps>(
  ({ isBackground = false, ...props }, ref): ReactElement => (
    <svg
      ref={ref}
      className={classNames(styles.graphic, isBackground && styles.background)}
      viewBox="0 0 50 50"
      {...props}
    >
      <g>
        <circle className={styles.backgroundDot} cx="16" cy="43" r="1.5" fill="none" />
        <circle className={styles.backgroundDot} cx="16" cy="7.3" r="1.5" fill="none" />
        <circle
          className={classNames(styles.bar, styles.barBackground)}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="8.5"
        />
        <circle
          id="js-graphic"
          className={classNames(styles.bar, styles.barRaised)}
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke={variables.greenPrimary}
          strokeWidth="8.5"
        />
        <circle className={classNames(styles.topRaisedDot, styles.raisedDot)} cx="45" cy="25" r="1.5" fill="none" />
        <circle id="js-raised-circle" className={styles.raisedDot} cx="16" cy="43" r="1.5" fill="none" />
        <circle id="js-raised-circle" className={styles.raisedDot} cx="16" cy="7.3" r="1.5" fill="none" />
      </g>
    </svg>
  )
);

GraphicCircle.displayName = 'GraphicCircle';

export default GraphicCircle;
