import { FunctionComponent } from 'react';

import { KatProgress } from '@amzn/katal-react';

import { useDataInitiativeSections } from 'src/components/pages/data-initiative/hooks/useDataInitiativeSections';
import { ClimatePledgeSection } from 'src/components/templates/ClimatePledgeSection';

export const DataInitiativePage: FunctionComponent = () => {
  const { isBundleLoading, dataInitiativeBundle } = useDataInitiativeSections();

  if (isBundleLoading || !dataInitiativeBundle) {
    return <KatProgress indeterminate type="circular" />;
  }

  return <ClimatePledgeSection />;
};
