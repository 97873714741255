import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { MessageBundle } from '@amzn/arb-tools';
import { PageType, ResourceType, SectionType } from '@amzn/coral_com-amazon-sssapinterface-model';
import { SearchResult as SearchResultModel } from '@amzn/coral_com-amazon-sssapinterface-model-structures-search';
import { KatGrid, KatGridItem, KatProgress } from '@amzn/katal-react';

import { SearchResult } from 'src/components/pages/search/SearchResult';
import {
  GETTING_STARTED_LINK_LABEL,
  SEARCH_ERROR_LABEL,
  NO_SEARCH_RESULTS_ACTION_LABEL,
  NO_SEARCH_RESULTS_LABEL,
  MAIN_SEARCH_RESULTS_LABEL,
  MAIN_SEARCH_RESULTS_LABEL_SINGULAR,
  RESOURCE_TYPE_TO_BUNDLE_MAP,
} from 'src/constants';
import { Pages } from 'src/fixtures/structures/pages';
import AllResources from 'src/fixtures/structures/resources';
import { extractDisplayFacetBySection } from 'src/helpers/page-helpers';
import { t } from 'src/helpers/translation-helpers';
import { emitCountMetric, logger, logError } from 'src/logger';
import { PROCESS_DIMENSION, RESULT_ID_DIMENSION, SEARCH_ERROR } from 'src/logging-helpers/metrics-constants';

import styles from './SearchResults.module.scss';

type ResultType = ResourceType | PageType | undefined;

export interface ResultContext {
  resultDescription: string;
  resultType: ResultType;
  resourceType: ResourceType;
}

export const getResultContext = (id: string, documentType: string): ResultContext => {
  let resultDescription = '';
  let resultType: ResultType;
  let resourceType = ResourceType.SECONDARY_HERO;

  if (documentType in ResourceType) {
    const resource = AllResources[id];
    resultDescription = resource?.display?.shortDescription ?? '';
    resultType = ResourceType[resource?.resourceType as keyof typeof ResourceType];
    resourceType = ResourceType[documentType as keyof typeof ResourceType];
  } else {
    const page = Pages[id];
    // impact area title and description are in SECONDARY_HERO
    if (page && page.sections) {
      const pageDisplay = extractDisplayFacetBySection(page.sections, SectionType.SECONDARY_HERO);
      resultDescription = pageDisplay?.shortDescription ?? '';
      resultType = PageType[page.type as keyof typeof PageType];
    }
  }

  return {
    resultDescription,
    resultType,
    resourceType,
  };
};

const toDisplayResult = ({ resultId, sourceURI, documentTitle, id, documentType }: SearchResultModel) => {
  const { resultDescription = '', resultType = '', resourceType } = getResultContext(id!, documentType);
  const translationBundleLabel = (resourceType && RESOURCE_TYPE_TO_BUNDLE_MAP[resourceType]) ?? '';

  if (!translationBundleLabel) {
    logger.warn(`skipping due to missing translation bundle: ${id}`);
    return null;
  }
  return {
    resultId,
    documentTitle: documentTitle?.text ?? '',
    sourceURI,
    description: resultDescription,
    resultType,
    translationBundleLabel,
  };
};

export interface SearchResultsProps {
  searchParams: URLSearchParams;
  searchResults?: SearchResultModel[];
  isLoading: boolean;
  hasError: boolean;
  searchBundle: MessageBundle;
}

const ResultsGrid = styled(KatGrid)`
  --card-background: none;
  --card-border-color: transparent;
`;

export const SearchResults: FunctionComponent<SearchResultsProps> = ({
  searchParams,
  searchResults,
  isLoading,
  hasError,
  searchBundle,
}) => {
  const submittedQuery = searchParams.get('q')?.trim();

  if (hasError) return <h3 className={styles.subtitle}>{t(searchBundle, SEARCH_ERROR_LABEL)}</h3>;

  if (isLoading) {
    return (
      <div role="status">
        <KatProgress katAriaLabel="search results loading" indeterminate size="medium" type="circular" />
      </div>
    );
  }

  const getUniqueFilter = (seen: Set<string>, ...keys: string[]) => {
    const compositeKey = keys.join('-');
    if (seen.has(compositeKey)) {
      return false; // Duplicate, skip it
    }

    seen.add(compositeKey);
    return true; // Unique, keep it
  };

  const uniqueResultKeys = new Set<string>();
  const uniqueDispResultKeys = new Set<string>();

  // filtering out SECONDARY_HERO as it is redundant to the Page which pulls info from that section
  // also filtering out on duplicate results
  const filteredSearchResults = searchResults
    ?.filter((result) => result && result.id && result.documentType !== ResourceType.SECONDARY_HERO)
    .filter((result) => getUniqueFilter(uniqueResultKeys, result.id!))
    .map((result) => {
      try {
        return toDisplayResult(result);
      } catch (error: any) {
        logError('SearchResults#filter', `Error processing result with id [${result?.id}], skipping`, error);
        emitCountMetric(SEARCH_ERROR, 1, { [PROCESS_DIMENSION]: 'transform', [RESULT_ID_DIMENSION]: result?.resultId });
        return null;
      }
    })
    .filter((dispResult) => dispResult && dispResult.documentTitle && dispResult.description && dispResult.sourceURI)
    .filter((dispResult) => getUniqueFilter(uniqueDispResultKeys, dispResult!.sourceURI, dispResult!.documentTitle));

  // No query has been submitted or results have not been retrieved
  if (!submittedQuery || !filteredSearchResults) return null;

  // Render search results or "no results" message
  return filteredSearchResults && filteredSearchResults.length > 0 ? (
    <>
      <h3 className={styles.subtitle}>
        {t(
          searchBundle,
          filteredSearchResults.length === 1 ? MAIN_SEARCH_RESULTS_LABEL_SINGULAR : MAIN_SEARCH_RESULTS_LABEL,
          { numOfResults: filteredSearchResults.length }
        )}
      </h3>
      <ResultsGrid variant="card" vertical-list>
        {filteredSearchResults.map((dispResult) => (
          <KatGridItem key={dispResult!.resultId}>
            <SearchResult
              {...dispResult}
              translationBundleLabel={dispResult!.translationBundleLabel}
              documentTitle={dispResult!.documentTitle ?? ''}
              description={dispResult!.description ?? ''}
            />
          </KatGridItem>
        ))}
      </ResultsGrid>
    </>
  ) : (
    <div className={styles.noResultsContainer}>
      <h3 className={styles.noResultsSubtitle}>{t(searchBundle, NO_SEARCH_RESULTS_LABEL)}</h3>
      <div className={styles.noResultsTextWrapper}>
        <span className={styles.noResultsParagraph}>{t(searchBundle, NO_SEARCH_RESULTS_ACTION_LABEL)}&nbsp;</span>
        <a className={styles.noResultsLink} href="/getting-started">
          {t(searchBundle, GETTING_STARTED_LINK_LABEL)}
        </a>
      </div>
    </div>
  );
};
