import classNames from 'classnames';
import { type ReactElement } from 'react';

import { convertToSentenceCase } from 'src/helpers/formatting-helpers';
import useDeviceSize from 'src/hooks/useDeviceSize.hook';

import styles from './AnchorPill.module.scss';

type AnchorPillProps = {
  copy: string;
  sectionId: string;
  isActive?: boolean;
};

export function AnchorPill({ copy, sectionId, isActive = false }: AnchorPillProps): ReactElement {
  const { isSmallDesktop } = useDeviceSize();

  let navbarHeight: number;

  if (isSmallDesktop) {
    navbarHeight = 96;
  } else {
    navbarHeight = 72;
  }

  const anchorLinksHeight = 96;
  const offsetHeight = navbarHeight + anchorLinksHeight;

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const targetElement = document.querySelector(sectionId) as HTMLElement | null;

    if (targetElement) {
      const top = isSmallDesktop
        ? window.scrollY + targetElement.getBoundingClientRect().top - navbarHeight
        : window.scrollY + targetElement.getBoundingClientRect().top - offsetHeight;
      window.scrollTo({ behavior: 'smooth', top });
    }
  };

  return (
    <a className={classNames(styles.anchorPill, isActive && styles.active)} href={sectionId} onClick={handleClick}>
      {convertToSentenceCase(copy)}
    </a>
  );
}
