import { FunctionComponent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { emitCountMetric, logger, truncateContext } from 'src/logger';
import { ORIGIN_DIMENSION, REFERER_ENCOUNTER } from 'src/logging-helpers/metrics-constants';

/**
 * Wrapper component that checks to see if the app is loaded with a ref tag (modeled as a "ref" query string).
 * If a ref tag is found, emit a metric.
 */
export const HandleReferer: FunctionComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const refString = searchParams.get('ref');
    if (refString) {
      const refContext = { [ORIGIN_DIMENSION]: refString };
      emitCountMetric(REFERER_ENCOUNTER, 1, refContext);
      logger.info('Referer tag found.', truncateContext(refContext));

      // clear out ref tag once captured to avoid emitting metric more than once per page ingress
      searchParams.delete('ref');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return null;
};
