import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import {
  Tagline,
  PrimaryButton,
  AsxImage,
  IconType,
  ExternalLink,
  DEFAULT_FALLBACK_IMG,
  AsxMarkdownContent,
} from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './ListComponent.module.scss';

export type ListItemProps = {
  theme?: SECTION_THEME;
  title?: string;
  headingSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  subtitle?: string;
  image?: ImageAttributes;
  taglines?: Array<string | undefined>;
  cta?: {
    href: string;
    icon: IconType;
  };
  label?: string;
  links?: { label: string; url: string; icon?: IconType }[];
};

export const ListItem: FunctionComponent<ListItemProps> = ({
  theme = SECTION_THEME.Light,
  image,
  title,
  headingSize = 'h4',
  subtitle,
  taglines,
  cta,
  label,
  links,
}) => {
  const { isDesktop } = useDeviceTracker();

  const HeadingTag = React.createElement(headingSize, { className: classnames(styles[theme], styles.title) }, title);

  return (
    <div key={`${title}-${label}`} className={classnames(styles.listItem, styles[theme])}>
      <div className={styles.cardHeader}>
        {isDesktop && image && (
          <div className={styles.imageContainer}>
            <AsxImage
              className={styles.image}
              alt={image.alt}
              src={image.url}
              data-testid="listComponentImage"
              fallback={DEFAULT_FALLBACK_IMG}
            />
          </div>
        )}
        <div className={classnames(styles.textContentContainer, styles[theme])}>
          {taglines && taglines.length > 0 && (
            <div className={styles.taglines}>
              {taglines?.map((tagline) => tagline && <Tagline key={tagline}>{tagline}</Tagline>)}
            </div>
          )}
          {title && HeadingTag}
          {subtitle && <AsxMarkdownContent className={styles.text} copy={subtitle} />}
          {links &&
            links.map((link) => (
              <ExternalLink
                key={link.label}
                link={link.url}
                text={link.label}
                className={styles.link}
                iconName={link.icon}
              />
            ))}
        </div>
      </div>
      {cta && (
        <div className={styles.cardCta}>
          {label && <span className={classnames(styles.label)}>{label}</span>}
          <PrimaryButton
            className={styles.button}
            data-testid="primaryButton"
            icon={cta.icon === 'download' ? 'download' : 'chevronTopRight'}
            theme="light"
            link={cta.href}
            download={cta.icon === 'download'}
          />
        </div>
      )}
    </div>
  );
};
interface ListComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  items: Array<ListItemProps>;
}

export const ListComponent: FunctionComponent<ListComponentProps> = ({ items, ...props }) => (
  <div className={styles.listComponent} id={styles.listComponent} {...(props as React.HTMLAttributes<HTMLDivElement>)}>
    <div className={styles.list}>
      {items.map(({ cta, label, image, taglines, title, subtitle, links }) => (
        <ListItem
          key={`${title}-${label}`}
          cta={cta}
          label={label}
          image={image}
          taglines={taglines}
          title={title}
          subtitle={subtitle}
          links={links}
        />
      ))}
    </div>
  </div>
);
