import { FunctionComponent, useState } from 'react';

import { KatProgress } from '@amzn/katal-react';
import { useBundle } from '@amzn/react-arb-tools';

import { IconType } from 'src/components/atoms';
import {
  AccordionsBanner,
  AccordionsComponent,
  AnchorLinks,
  Card,
  DynamicList,
  Modal,
  ModalProps,
  PageSection,
  SecondaryHero,
  Slider,
  TextComponent,
} from 'src/components/common';
import { ClimatePledgeSection } from 'src/components/templates/ClimatePledgeSection';
import { COMMON_BUNDLE_NAME, SCIENCE_EXCHANGE_PAGE_BUNDLE_NAME } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';

import { useScienceExchangeData } from './useScienceExchangeData';

enum OUR_APPROACH_STEPS {
  MODEL = 'Model',
  INVENT = 'Invent',
  DEVELOP = 'Develop',
  VALIDATE = 'Validate',
  SCALE = 'Scale',
}

export const ScienceExchangePage: FunctionComponent = () => {
  const [bundle, isBundleLoading] = useBundle(SCIENCE_EXCHANGE_PAGE_BUNDLE_NAME);
  const [bundleCommon] = useBundle(COMMON_BUNDLE_NAME);
  const { focusAreaContent, crossSectorContent, ourApproachModalContent } = useScienceExchangeData();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState<Partial<ModalProps>>(ourApproachModalContent[OUR_APPROACH_STEPS.MODEL]);

  const handleModalUpdate = (step: OUR_APPROACH_STEPS) => {
    setModalData(ourApproachModalContent[step]);
    setIsModalVisible(true);
  };

  if (isBundleLoading) {
    return <KatProgress indeterminate type="circular" />;
  }

  return (
    <>
      <PageSection>
        <SecondaryHero
          heading={t(bundle, 'scienceExchange_title')}
          copy={t(bundle, 'scienceExchange_description')}
          image={{
            alt: t(bundle, 'scienceExchange_heroImageAlt'),
            src: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-hero.jpg',
          }}
          theme={SECTION_THEME.Light}
        />
      </PageSection>
      <AnchorLinks theme={SECTION_THEME.Dark} />
      <PageSection id="focus-areas" theme="slate-dark" data-anchor>
        <TextComponent
          heading={t(bundle, 'focusAreas_title') ?? ''}
          headingSize="h2"
          copy={t(bundle, 'focusAreas_description')}
          theme={SECTION_THEME.Dark}
        />
        <AccordionsComponent theme={SECTION_THEME.Dark} content={focusAreaContent} />
      </PageSection>
      <PageSection id="cross-sector-solutions" data-anchor>
        <TextComponent
          heading={t(bundle, 'crossSectorSolutions_title') ?? ''}
          headingSize="h2"
          copy={t(bundle, 'crossSectorSolutions_description')}
          theme={SECTION_THEME.Light}
        />
        <AccordionsComponent
          theme={SECTION_THEME.Light}
          content={crossSectorContent}
          variant={crossSectorContent[0]?.variant}
        />
      </PageSection>

      <Modal
        title={modalData.title}
        subtitle={modalData.subtitle}
        isVisible={isModalVisible}
        close={() => setIsModalVisible(false)}
        list={modalData.list}
      />
      <PageSection id="our-approach" theme="grey-tertiary" data-anchor>
        <DynamicList
          heading={t(bundle, 'ourApproach_title') ?? ''}
          subtitle={t(bundle, 'ourApproach_description') ?? ''}
          // TODO: Update items and list component to support opening modals
          items={[
            {
              title: t(bundle, 'ourApproachModel_title') ?? '',
              body: t(bundle, 'ourApproachModel_description') ?? '',
              link: {
                text: t(bundle, 'ourApproach_cta') ?? '',
                icon: 'chevronRight',
                onClickOverride: () => handleModalUpdate(OUR_APPROACH_STEPS.MODEL),
              },
            },
            {
              title: t(bundle, 'ourApproachInvent_title') ?? '',
              body: t(bundle, 'ourApproachInvent_description') ?? '',
              link: {
                text: t(bundle, 'ourApproach_cta') ?? '',
                icon: 'chevronRight',
                onClickOverride: () => handleModalUpdate(OUR_APPROACH_STEPS.INVENT),
              },
            },
            {
              title: t(bundle, 'ourApproachDevelop_title') ?? '',
              body: t(bundle, 'ourApproachDevelop_description') ?? '',
              link: {
                text: t(bundle, 'ourApproach_cta') ?? '',
                icon: 'chevronRight',
                onClickOverride: () => handleModalUpdate(OUR_APPROACH_STEPS.DEVELOP),
              },
            },
            {
              title: t(bundle, 'ourApproachValidate_title') ?? '',
              body: t(bundle, 'ourApproachValidate_description') ?? '',
              link: {
                text: t(bundle, 'ourApproach_cta') ?? '',
                icon: 'chevronRight',
                onClickOverride: () => handleModalUpdate(OUR_APPROACH_STEPS.VALIDATE),
              },
            },
            {
              title: t(bundle, 'ourApproachScale_title') ?? '',
              body: t(bundle, 'ourApproachScale_description') ?? '',
              link: {
                text: t(bundle, 'ourApproach_cta') ?? '',
                icon: 'chevronRight',
                onClickOverride: () => handleModalUpdate(OUR_APPROACH_STEPS.SCALE),
              },
            },
          ]}
        />
      </PageSection>
      <PageSection id="case-studies" theme="slate-dark" data-anchor>
        <TextComponent
          heading={t(bundle, 'caseStudies_title') ?? ''}
          copy={t(bundle, 'caseStudies_description')}
          headingSize="h2"
          theme={SECTION_THEME.Dark}
        />
        <Slider size="xlarge" theme={SECTION_THEME.Dark}>
          <Card
            theme={SECTION_THEME.Dark}
            key={t(bundle, 'caseStudy1_title')}
            title={t(bundle, 'caseStudy1_title')}
            subtitle={t(bundle, 'caseStudy1_description')}
            alt={t(bundle, 'caseStudy1_imageAlt')}
            image="https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-casestudies-1.png"
            pill={t(bundleCommon, 'caseStudy_pill')}
            icon={'chevronRight' as IconType}
            target="_blank"
            link={{
              label: t(bundleCommon, 'readCaseStudy_label'),
              url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/case-study/case-study-the-science-exchange-is-facilitating-collaborations-across-the-value-chain-to-change-how-we-recycle-plastics.pdf',
            }}
          />
          <Card
            theme={SECTION_THEME.Dark}
            key={t(bundle, 'caseStudy2_title')}
            title={t(bundle, 'caseStudy2_title')}
            subtitle={t(bundle, 'caseStudy2_description')}
            alt={t(bundle, 'caseStudy2_imageAlt')}
            image="https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-casestudies-2.png"
            pill={t(bundleCommon, 'caseStudy_pill')}
            icon={'chevronRight' as IconType}
            target="_blank"
            link={{
              label: t(bundleCommon, 'readCaseStudy_label'),
              url: 'https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/case-study/case-study-how-the-science-exchange-is-supporting-the-scaling-of-carbon-capture-and-storage-technology-for-the-pulp-and-paper-industry.pdf',
            }}
          />
        </Slider>
      </PageSection>
      <PageSection id="sustainability-innovation-lab-network" data-anchor>
        <AccordionsBanner
          title={t(bundle, 'sustainabilityLab_title') ?? ''}
          alt={t(bundle, 'sustainabilityLabAccordionImageAlt')}
          imageUrl="https://dn838xj7iw6s8.cloudfront.net/locale/en-us/resource-type/image/variation/desktop/thescienceexchange-labs-1.jpg"
          accordions={[
            {
              title: t(bundle, 'sustainabilityLabIntroItem_title') ?? '',
              content: t(bundle, 'sustainabilityLabIntroItem_description') ?? '',
            },
            {
              title: t(bundle, 'sustainabilityLabItem1_title') ?? '',
              content: t(bundle, 'sustainabilityLabItem1_description') ?? '',
            },
            {
              title: t(bundle, 'sustainabilityLabItem2_title') ?? '',
              content: t(bundle, 'sustainabilityLabItem2_description') ?? '',
              links: [
                {
                  text: t(bundle, 'sustainabilityLabItem2_link') ?? '',
                  link: 'https://www.amazon-packaging.com/',
                },
              ],
            },
            {
              title: t(bundle, 'sustainabilityLabItem3_title') ?? '',
              content: t(bundle, 'sustainabilityLabItem3_description') ?? '',
            },
            {
              title: t(bundle, 'sustainabilityLabItem4_title') ?? '',
              content: t(bundle, 'sustainabilityLabItem4_description') ?? '',
            },
            {
              title: t(bundle, 'sustainabilityLabItem5_title') ?? '',
              content: t(bundle, 'sustainabilityLabItem5_description') ?? '',
            },
          ]}
        />
      </PageSection>
      <ClimatePledgeSection />
    </>
  );
};
