export enum WEBLAB_NAME {
  ASX_DPM_LAUNCH = 'ASX_DPM_LAUNCH_989324',
  ASX_ASDI_LAUNCH = 'ASX_ASDI_LAUNCH_1040381',
}

export enum WEBLAB_TREATMENT {
  C = 'C',
  T1 = 'T1',
}

// Builds an in memory cache of Weblab treatments injected in the html header's <meta> tag by Mons
// Adapted from: https://w.amazon.com/bin/view/Mons/Weblab/Configuration/#HDeliveryFrontend:HTMLResponseMetaTagFormat
function buildWeblabCache(): Record<string, string> {
  const weblabContent = document.head.querySelector('meta[name="a2z:mons_weblab_treatment"]')?.getAttribute('content');
  if (!weblabContent) {
    return {};
  }

  const weblabCache = weblabContent.split(',').reduce((obj, weblabNameTreatment) => {
    const [thisWeblabName, treatment] = weblabNameTreatment.split('=', 2);
    return {
      ...obj,
      [thisWeblabName as WEBLAB_NAME]: treatment as WEBLAB_TREATMENT,
    };
  }, {});

  return weblabCache;
}

const weblabCache = buildWeblabCache();

export const getTreatment = (weblab: WEBLAB_NAME): WEBLAB_TREATMENT | undefined => {
  if (!weblabCache) buildWeblabCache();
  return weblabCache[weblab] as WEBLAB_TREATMENT;
};
