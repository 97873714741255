import { useState, useEffect, useContext } from 'react';

import { GetSearchResultsResponse } from '@amzn/coral_com-amazon-sssapinterface-model-operations';
import { SearchResult as SearchResultModel } from '@amzn/coral_com-amazon-sssapinterface-model-structures-search';

import { SearchClientContext } from 'src/clients/SearchClientProvider';
import { MAX_SEARCH_QUERY_LENGTH } from 'src/constants';
import { emitCountMetric, logger, logError, truncateContext } from 'src/logger';
import { PROCESS_DIMENSION, SEARCH_ERROR } from 'src/logging-helpers/metrics-constants';

const mapResultsToId = (response: GetSearchResultsResponse) => {
  const { sanitizedQuery, totalNumberOfResults, searchResults } = response;
  const resultIds = searchResults.map((result) => result?.resultId);

  return {
    sanitizedQuery,
    resultIds,
    totalNumberOfResults,
  };
};

const logSearchResults = (response: GetSearchResultsResponse) => {
  logger.info('Search results:', truncateContext(mapResultsToId(response)));
};

const fetchSearchResultsData = async (
  fetchSearchResults: (query: string) => Promise<GetSearchResultsResponse>,
  query: string,
  setSearchResults: (results: SearchResultModel[]) => void,
  setIsLoading: (isLoading: boolean) => void,
  setHasError: (hasError: boolean) => void
) => {
  try {
    setIsLoading(true);
    setHasError(false);
    const trimmedQuery = query.trim().slice(0, MAX_SEARCH_QUERY_LENGTH);
    const response = await fetchSearchResults(trimmedQuery);
    logSearchResults(response);
    setSearchResults(response.searchResults);
  } catch (err: any) {
    logError('use-search#fetchSearchResultsData', 'Error fetching search results', err);
    setHasError(true);
    emitCountMetric(SEARCH_ERROR, 1, { [PROCESS_DIMENSION]: 'fetch' });
  } finally {
    setIsLoading(false);
  }
};

const useSearch = (searchParams: URLSearchParams) => {
  const [searchResults, setSearchResults] = useState<SearchResultModel[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const { fetchSearchResults } = useContext(SearchClientContext) ?? {};

  useEffect(() => {
    const submittedQuery = searchParams.get('q');
    if (!submittedQuery?.trim()) {
      setIsLoading(false);
      return;
    }
    if (fetchSearchResults) {
      fetchSearchResultsData(fetchSearchResults, submittedQuery, setSearchResults, setIsLoading, setHasError);
    } else {
      logError('use-search#useEffect', 'Search context is not available');
      emitCountMetric(SEARCH_ERROR, 1, { [PROCESS_DIMENSION]: 'context' });
      setIsLoading(false);
      setHasError(true);
    }
  }, [fetchSearchResults, searchParams]);
  return { searchResults, isLoading, hasError };
};

export default useSearch;
