import classNames from 'classnames';
import { type ReactElement } from 'react';

import {
  Heading,
  HEADING_SIZE,
  PrimaryButton,
  PRIMARY_BUTTON_SIZE,
  Icon,
  AsxImage,
  DEFAULT_FALLBACK_IMG,
} from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { ImageAttributes } from 'src/interfaces/ImageAttributes';

import styles from './SmallBanner.module.scss';

interface SmallBannerProps extends React.HTMLAttributes<HTMLDivElement> {
  theme?: SECTION_THEME;
  heading?: string;
  cta: {
    label?: string;
    href?: string;
  };
  image: ImageAttributes;
  logo?: boolean;
}

export function SmallBanner({
  heading,
  cta,
  image,
  logo,
  theme = SECTION_THEME.Dark,
  ...props
}: SmallBannerProps): ReactElement {
  return (
    <div className={classNames(styles.smallBanner, styles[theme])} {...(props as React.HTMLAttributes<HTMLDivElement>)}>
      <div className={styles.copyContainer}>
        <Heading className={styles.heading} size={HEADING_SIZE.h3} as="h3">
          {heading}
        </Heading>
        <PrimaryButton
          className={styles.cta}
          link={cta.href}
          icon="chevronTopRight"
          label={cta.label}
          theme={theme}
          buttonSize={PRIMARY_BUTTON_SIZE.Regular}
          isBold
        />
      </div>
      <div className={styles.imagesContainer}>
        {logo && (
          <div className={styles.logoContainer}>
            <Icon className={styles.logo} name="tcp" />
          </div>
        )}
        <div className={styles.imageContainer}>
          <AsxImage
            className={styles.image}
            alt={image.alt}
            src={image.url}
            data-testid="smallBannerImage"
            fallback={DEFAULT_FALLBACK_IMG}
          />
        </div>
      </div>
    </div>
  );
}
