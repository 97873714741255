import { FunctionComponent, HTMLAttributes } from 'react';

import { Heading, HEADING_SIZE } from 'src/components/atoms/heading/Heading';
import { PrimaryButton } from 'src/components/atoms/primary-button/PrimaryButton';
import AssetGraphic from 'src/components/common/asset-graphic/AssetGraphic';

import styles from './AssetBanner.module.scss';

interface AssetBannerProps extends HTMLAttributes<HTMLDivElement> {
  description: string;
  heading: string;
  cta?: {
    link: string;
    copy: string;
  };
}

const AssetBanner: FunctionComponent<AssetBannerProps> = ({ description, heading, cta }) => (
  <div className={styles.assetBanner} id={styles.assetBanner}>
    <AssetGraphic className={styles.assetGraphic} />
    <div className={styles.content}>
      <Heading className={styles.heading} as="h3" size={HEADING_SIZE.h3}>
        {heading}
      </Heading>
      <p className={styles.body}>{description}</p>
      {cta && (
        <PrimaryButton
          className={styles.cta}
          label={cta.copy}
          link={cta.link}
          theme="dark"
          icon="chevronRight"
          data-testid="assetBannerCta"
        />
      )}
    </div>
  </div>
);

export default AssetBanner;
