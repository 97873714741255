import classNames from 'classnames';
import { HTMLAttributes, type ReactElement } from 'react';

import { Icon } from 'src/components/atoms';
import { IconType } from 'src/components/atoms/icons/icons';

import styles from './ExternalLink.module.scss';

interface ExternalLinkProps extends HTMLAttributes<HTMLAnchorElement> {
  text: string;
  link?: string;
  bold?: boolean;
  variant?: 'raised' | 'outlined';
  showIcon?: boolean;
  iconName?: IconType;
  onClick?: () => void;
}

export function ExternalLink({
  text,
  link,
  showIcon = true,
  iconName = 'chevronTopRight',
  variant = 'raised',
  bold = false,
  className,
  onClick,
  ...props
}: ExternalLinkProps): ReactElement {
  return (
    <a
      target="_blank"
      type="button"
      rel="noreferrer"
      {...(link && { href: link })}
      {...(onClick && { onClick })}
      className={classNames(styles.externalLink, className)}
      aria-label="Opens in a new tab"
      {...props}
    >
      <span className={classNames(styles.text, bold && styles.bold)}>{text}</span>

      {showIcon && (
        <div className={classNames(styles.iconsWrapper, variant === 'outlined' && styles.iconsWrapperOutlined)}>
          <Icon name={iconName} className={styles.icon} />
          <Icon name={iconName} className={styles.iconDuplicated} />
        </div>
      )}
    </a>
  );
}
