import { FunctionComponent } from 'react';

import { KatDropdown, KatToggle } from '@amzn/katal-react';

import { Dropdown, DropdownOption, Icon, Toggle } from 'src/components/atoms';
import { CtaAttributes } from 'src/interfaces/CommonAttributes';

import styles from './Filters.module.scss';

export interface FilterEntryProps {
  name: string;
  label: string;
  controlType: 'toggle' | 'select';
  onFilterChange: (e: KatToggle.ChangeEvent | KatDropdown.ChangeEvent) => void;
  options?: DropdownOption[];
  placeholder?: string;
}

interface ExtendedFilterEntryProps extends FilterEntryProps {
  index: number;
  value: string | boolean;
}

const FilterEntry: FunctionComponent<ExtendedFilterEntryProps> = ({
  index,
  name,
  value,
  label,
  controlType,
  onFilterChange,
  options,
  placeholder,
}) => (
  <div className={styles.FilterEntry}>
    <span className={styles.FilterIndex}>0{index + 1}. </span>
    <span className={styles.FilterLabel}>{label}</span>
    {controlType === 'toggle' ? (
      <span className={styles.FilterInputToggle}>
        <Toggle name={name} state={value as boolean} onChange={onFilterChange} />
      </span>
    ) : (
      <Dropdown
        className={styles.FilterInputSelect}
        name={name}
        value={value as string}
        placeholder={placeholder}
        onChange={onFilterChange}
        options={options}
      />
    )}
  </div>
);

interface FiltersProps {
  heading?: string;
  filters: FilterEntryProps[];
  values: Record<string, string | boolean>;
  cta?: CtaAttributes;
}

export const Filters: FunctionComponent<FiltersProps> = ({ heading, filters, values, cta }) => (
  <div className={styles.Filters}>
    <h4 className={styles.heading}>{heading}</h4>
    <div className={styles.FilterEntryContainer}>
      {filters.map((filter: FilterEntryProps, index: number) => (
        <FilterEntry key={filter.name} index={index} value={values[filter.name]} {...filter} />
      ))}
    </div>
    {cta && (
      <div className={styles.CtaEntry}>
        <div className={styles.CtaLink}>
          {cta.icon && <Icon className={styles.CtaEntryIcon} name={cta.icon} />}
          <a href={cta.url}>{cta.label}</a>
        </div>
      </div>
    )}
  </div>
);
