import classNames from 'classnames';
import { type ReactElement } from 'react';

import { AsxImage, DEFAULT_FALLBACK_IMG } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
// TODO get final fallback image from product team https://app.asana.com/0/1206972973144714/1207649452361341/f

import styles from './AccordionComponentCard.module.scss';

type AccordionComponentCardProps = {
  theme?: SECTION_THEME;
  title: string;
  subtitle: string;
  image: { src: string; alt: string };
};

export function AccordionComponentCard({
  title,
  subtitle,
  image,
  theme = SECTION_THEME.Light,
}: AccordionComponentCardProps): ReactElement {
  return (
    <div className={classNames(styles.AccordionComponentCard, styles[theme])}>
      <div className={styles.titleWrapper}>
        <h3 className={styles.title}>{title}</h3>
        <AsxImage
          key={`${image.src}-${image.alt}`}
          className={styles.image}
          src={image.src}
          alt={image.alt}
          fallback={DEFAULT_FALLBACK_IMG}
        />
      </div>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  );
}
