import classNames from 'classnames';
import { type ReactElement, useCallback, useState } from 'react';

import { AccordionWithIndex, AsxImage, DEFAULT_FALLBACK_IMG, GridSvg } from 'src/components/atoms';
import { type AccordionWithIndexProps } from 'src/components/atoms/accordion-with-index/AccordionWithIndex';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
// TODO get final fallback image from product team https://app.asana.com/0/1206972973144714/1207649452361341/f

import styles from './AccordionsBanner.module.scss';

export type AccordionsBannerProps = {
  theme?: SECTION_THEME;
  title: string;
  accordions: Array<AccordionWithIndexProps>;
  imageUrl: string;
  alt?: string;
};

export function AccordionsBanner({
  title,
  accordions,
  imageUrl,
  alt,
  theme = SECTION_THEME.Light,
}: AccordionsBannerProps): ReactElement {
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number>(0);

  const handleAccordionClick = useCallback((index: number) => {
    setOpenAccordionIndex((previousIndex) => (previousIndex === index ? -1 : index));
  }, []);

  const onAccordionClick = useCallback(
    (index: number) => () => {
      handleAccordionClick(index);
    },
    [handleAccordionClick]
  );

  return (
    <div className={classNames(styles.AccordionsBanner, styles[theme])}>
      <div className={styles.imageWrapper}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.imagesContainer}>
          <GridSvg theme={theme} />
          <AsxImage src={imageUrl} alt={alt ?? ''} className={styles.image} fallback={DEFAULT_FALLBACK_IMG} />
        </div>
      </div>
      <div className={styles.accordionsWrapper}>
        {accordions.map((accordion, index) => {
          const key = `${accordion.title}-${index}`;
          const { index: _, ...rest } = accordion; // Exclude `index` by destructuring

          return (
            <AccordionWithIndex
              key={key}
              index={index}
              isOpen={index === openAccordionIndex}
              onClick={onAccordionClick(index)}
              theme={theme}
              showIndexDecoration
              {...rest}
              dataTestId={`accordion-${index}`}
            />
          );
        })}
      </div>
    </div>
  );
}
