import { BrowserRouter, Route, Routes } from 'react-router-dom';
import arbManifest from 'translations/arbManifest';

import { MbmProvider } from '@amzn/react-arb-tools';

import { SearchClientProvider } from 'src/clients/SearchClientProvider';
import { getTreatment, WEBLAB_NAME, WEBLAB_TREATMENT } from 'src/clients/weblab-client';
import { ROUTES } from 'src/data/enums/Routes';
import { getMbmOptions } from 'src/helpers';

import {
  DataInitiativePage,
  ErrorBoundary,
  HomePage,
  FocusAreaPage,
  GettingStartedPage,
  NotFoundPage,
  ScienceExchangePage,
  SearchResultsPage,
  MeasurementPage,
} from './pages';
import { HandleReferer, ScrollToSection } from './wrappers';

const mbmOptions = getMbmOptions(arbManifest);
const shouldRenderDataInitiativeRoute = WEBLAB_TREATMENT.T1 === getTreatment(WEBLAB_NAME.ASX_ASDI_LAUNCH);

const App = () => (
  <MbmProvider {...mbmOptions}>
    <div className="ash-theme">
      <BrowserRouter>
        <ErrorBoundary>
          <ScrollToSection />
          <HandleReferer />
          <Routes>
            <Route path={ROUTES.ROOT} element={<HomePage />} />
            <Route path={`${ROUTES.FOCUS_AREAS}/:focusAreaUrl`} element={<FocusAreaPage />} />
            <Route path={ROUTES.GETTING_STARTED} element={<GettingStartedPage />} />
            <Route path={ROUTES.SCIENCE_EXCHANGE} element={<ScienceExchangePage />} />
            <Route path={ROUTES.MEASUREMENT} element={<MeasurementPage />} />
            {shouldRenderDataInitiativeRoute && (
              <Route path={ROUTES.DATA_INITIATIVE} element={<DataInitiativePage />} />
            )}
            <Route
              path={ROUTES.SEARCH}
              element={
                <SearchClientProvider>
                  <SearchResultsPage />
                </SearchClientProvider>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
    </div>
  </MbmProvider>
);

export default App;
